import * as React from "react";

function SvgInstagram(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 70 68"
      {...props}
    >
      <defs>
        <linearGradient
          id="Instagram_svg__a"
          x1={0.191}
          y1={1.051}
          x2={0.809}
          y2={-0.051}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fd5" />
          <stop offset={0.175} stopColor="#fc5245" />
          <stop offset={0.328} stopColor="#ff543f" />
          <stop offset={0.504} stopColor="#e64771" />
          <stop offset={0.643} stopColor="#d53e91" />
          <stop offset={0.761} stopColor="#cc39a4" />
          <stop offset={0.841} stopColor="#c837ab" />
        </linearGradient>
      </defs>
      <g data-name="Group 779">
        <path data-name="Rectangle 255" fill="#fff" d="M6 6h58v56H6z" />
        <path
          data-name="Path 17431"
          d="M838.678 395.428a9.722 9.722 0 11-9.718-9.44 9.582 9.582 0 019.718 9.44zm11.66-11.327v22.656a9.592 9.592 0 01-9.717 9.439H817.3a9.592 9.592 0 01-9.717-9.439V384.1a9.592 9.592 0 019.717-9.439h23.322a9.592 9.592 0 019.716 9.439zm-7.774 11.327a13.61 13.61 0 10-13.6 13.215 13.413 13.413 0 0013.6-13.215zm3.888-14.159a2.917 2.917 0 10-2.916 2.832 2.875 2.875 0 002.916-2.832zm17.508-9.841v48a10 10 0 01-10 10h-50a10 10 0 01-10-10v-48a10 10 0 0110-10h50a10 10 0 0110 10zm-9.735 12.672a13.43 13.43 0 00-13.6-13.215H817.3a13.43 13.43 0 00-13.6 13.215v22.656a13.431 13.431 0 0013.6 13.214h23.322a13.43 13.43 0 0013.6-13.214z"
          transform="translate(-793.96 -361.428)"
          fill="url(#Instagram_svg__a)"
        />
      </g>
    </svg>
  );
}

export default SvgInstagram;
