import React, {useContext } from "react";
import Logo from "../svgComponents/Logo";
import GetInTouch from "../svgComponents/GetInTouch";
import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-scroll";

import { MobileMenu } from "./MobileMenu";

import { types } from "../context/Reducer";
import { AppContext } from "../context/Context";

export const Header = (props) => {
  const [state, dispatch] = useContext(AppContext);

  function handleMobileMenu(){
    dispatch({ type: types.toggleMenu})
  }

  function logoClick(){
    scroll.scrollToTop()
    if (state.mobileMenuState === true){
      dispatch({ type: types.toggleMenu})
    }else{
      return
    }
    
  }

  return (
    <>
    <div className="header">
      <div className="header--container">
        <Logo
          fill="#fff"
          className="header__logo"
          onClick={() => logoClick()}
        />
        <Link
        to="contact"
        smooth={true}
        duration={1000}
        activeClass={"active"}
        spy={true}
      >
        <GetInTouch className="header__cta" />
        </Link>
        <div className="header__hamburguer" onClick={() =>  handleMobileMenu()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.837"
            height="20.429"
            viewBox="0 0 25.837 20.429"
          >
            <path
              fill="#fff"
              d="M8.8 16a1.8 1.8 0 1 0 0 3.605h22.234a1.8 1.8 0 0 0 0-3.605zm0 8.412a1.8 1.8 0 1 0 0 3.605h22.234a1.8 1.8 0 0 0 0-3.605zm0 8.412a1.8 1.8 0 0 0 0 3.605h22.234a1.8 1.8 0 0 0 0-3.605z"
              transform="translate(-7 -16)"
            />
          </svg>
        </div>
      </div>
    </div>
    <div className={`mobile-menu-container ${state.mobileMenuState ? `active` : `unactive`}`}>
      <MobileMenu/>
    </div>
    </>
  );
};
