import * as React from "react";

function SvgFacebook(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 70 68"
      {...props}
    >
      <g transform="translate(.488 .132)">
        <rect
          data-name="Rectangle 21"
          width={70}
          height={68}
          rx={10}
          transform="translate(-.488 -.132)"
          fill="#0b85e0"
        />
        <path
          data-name="Path 8594"
          d="M40.608 44.634h8.728l1.371-8.866h-10.1v-4.847c0-3.683 1.2-6.949 4.649-6.949h5.539v-7.736a47.049 47.049 0 00-6.917-.418c-8.117 0-12.876 4.286-12.876 14.053v5.9h-8.347v8.866h8.34l.007 23.232h9.728z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgFacebook;
