import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, A11y } from "swiper";
import { Client } from "../svgComponents";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";

import {
  CreandoSonrisas,
  Escuela,
  MrBike,
  Palenzuela,
  Panifino,
  RelajaAguas,
  ZeroDesperdicio,
} from "../ClientsComponents";

export const Clients = (props) => {
  SwiperCore.use([Navigation, A11y]);
  return (
    <section id="clients" className="clients">
        <div className="mobile-selector">
                <span className="mobile-selector__icon">
                    <Client/>
                </span>
                <h2>Clients</h2>
            </div>
      <div className="clients__carousel">
        <Swiper
          spaceBetween={80}
          slidesPerView={4}
          slidesOffsetBefore={0}
          slidesOffsetAfter={200}
          initialSlide={2}
          navigation
          loop={true}
          breakpoints={{
            280: {
              slidesPerView: 1
            },
            320: {
              slidesPerView: 1
            },
            480: {
              slidesPerView: 2.5
            },
            768: {
              slidesPerView: 3.5
            },
            1440: {
              slidesPerView: 4
            },
            1600: {
              slidesPerView: 4
            },
            1900: {
              slidesPerView: 4
            },
            2200: {
              slidesPerView: 5
            },
          }}
        >
          <SwiperSlide>
            <CreandoSonrisas />
          </SwiperSlide>
          <SwiperSlide>
            <Escuela />
          </SwiperSlide>
          <SwiperSlide>
            <MrBike />
          </SwiperSlide>
          <SwiperSlide>
            <Palenzuela />
          </SwiperSlide>
          <SwiperSlide>
            <Panifino />
          </SwiperSlide>
          <SwiperSlide>
            <RelajaAguas />
          </SwiperSlide>
          <SwiperSlide>
            <ZeroDesperdicio />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};
