import * as React from "react";

function SvgEscuela(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 229.941 246.919"
      {...props}
    >
      <g data-name="Group 827">
        <path
          data-name="Path 17845"
          d="M158.661 91.791c3.658.278 6.653.372 9.6.8 1.164.167 3.062.964 3.146 1.655a5.705 5.705 0 01-1.373 3.9 10.836 10.836 0 01-3.915 2.711c-13.741 5.952-25.862 14.628-38.41 22.6-6.989 4.44-14.209 8.614-22.5 10.112-6.918 1.251-12.972-1.183-18.26-5.555-1.65-1.364-1.02-2.394 1.331-3.212 6.012-2.093 12.041-4.171 17.916-6.609a18.744 18.744 0 006.1-4.818c-3.785.225-7.566.571-11.353.657-18.589.426-35.1-5.645-50.118-16.247-9.431-6.657-16.9-15.3-24.173-24.117-8.459-10.254-16.4-20.889-22.889-32.531a70.6 70.6 0 01-3.421-7.085c-.887-2.115-.041-3.126 2.211-2.639 21.962 4.749 43.477 10.979 61.382 25.189 8.2 6.511 14.979 14.828 22.368 22.359 9.215 9.393 19.662 16.853 32.915 18.979 9.319 1.495 17.885-1.583 25.623-6.7 9.993-6.611 16.63-15.832 20.665-27.092 6.22-17.356 13.217-34.361 24.042-49.485 3.534-4.937 7.558-9.421 12.81-12.623a18.43 18.43 0 013.533-1.749c1.755-.6 2.865-.4 3.243 1.985 2.828 17.883-1.25 34.4-9.97 49.886-9.1 16.157-22.522 28.148-37.977 38.037-.627.404-1.264.798-2.526 1.592zm-6.149 1.646c1.2-.77 2.075-1.382 3-1.917 12.559-7.3 23.919-16.09 33.212-27.33 10.341-12.508 17.1-26.7 19.03-42.919a59.053 59.053 0 00-.568-19.392 6.79 6.79 0 00-.972.222c-7 2.927-11.694 8.476-15.848 14.455-10.029 14.435-16.836 30.477-22.678 46.942-4.315 12.16-11.137 22.36-22.134 29.405-7.9 5.059-16.586 8.079-25.967 6.74-14.127-2.018-25.164-9.951-34.89-19.946-6.639-6.823-12.967-14-20.042-20.332-8.982-8.045-19.9-13.023-31.087-17.306a188.981 188.981 0 00-31.75-8.909c.669 1.528 1.065 2.642 1.621 3.669 9.436 17.409 21.564 32.825 34.827 47.443a86.394 86.394 0 0039.331 25c12.655 3.885 25.557 4.067 39.168 1.48-7.108 10.357-18.031 12.675-28.828 16.162 6.269 4.3 12.4 6.793 19.476 4.387a63.568 63.568 0 0011.911-4.992c8.674-5.114 16.974-10.859 25.63-16 6.859-4.076 14.049-7.592 21.031-11.468a33.667 33.667 0 004.221-3.294l-.328-1.108z"
          fill="#fff"
        />
        <path
          data-name="Path 17846"
          d="M92.076 178.999c-3.791.405-4.252.193-4.443-3.208q-1-17.914-1.738-35.844c-.031-.737 1.1-2.222 1.37-2.151 1.175.313 3.051.925 3.19 1.695a88.54 88.54 0 01.974 10.351c.118 1.956.022 3.926.022 6.2a18.107 18.107 0 002.173-1.192c4.683-3.481 9.334-7.006 14.016-10.49.592-.44 1.335-1.056 1.954-1a18.686 18.686 0 013.548 1c-.465.929-.707 2.121-1.436 2.737-2.369 2-4.993 3.7-7.344 5.719a3 3 0 00-.8 2.688c3.357 6.573 6.892 13.055 10.378 19.563l1.249-.228c.871-2.983 1.763-5.961 2.612-8.952 2.83-9.969 5.633-19.946 8.478-29.912a11.588 11.588 0 011.421-3.644c.732-1.007 2.028-2.321 2.985-2.251a4.189 4.189 0 012.763 2.563 61.134 61.134 0 011.9 8.475c.638 3.6 1.072 7.234 1.709 10.834a11.924 11.924 0 001.154 2.671c.152.333.522.677.481.972-.9 6.411 2.563 11.849 3.924 17.751.209.909.521 1.793.728 2.7.348 1.526.588 3.238-1.4 3.642a2.888 2.888 0 01-3.734-2.217c-1.563-5.669-3.363-11.275-4.825-16.968-.471-1.832-1.121-2.5-3.006-2.353-5.778.456-5.173-1.158-6.972 5.605-1.008 3.789-2.21 7.526-3.346 11.28-.573 1.893-1.046 3.915-3.554 4.141-2.8.252-5.5.2-7.131-2.689q-5.118-9.069-10.24-18.135c-.219-.388-.42-.787-.731-1.375-2.041 1.477-4.173 2.62-5.717 4.3-.795.866-.775 2.7-.718 4.087.185 4.556 1.581 9.097.106 13.635zm34.059-25.146l5.512-.656-1.549-10.946-.653-.04z"
          fill="#fff"
        />
        <path
          data-name="Path 17847"
          d="M187.466 135.753c-7.1-1.047-13.749 2.693-19.978-.9l-10.588.909c0 1.427.005 2.826 0 4.225-.053 12.072-.079 24.144-.2 36.214-.015 1.429-.159 3.039-2.417 3.079-2.13.037-2.6-1.155-2.595-2.837.009-4.241.1-8.483.11-12.724.018-9.427.006-18.853.006-28.7-3.515 0-6.4.055-9.286-.014-3.376-.081-4.623-1.174-5.42-4.814a22.2 22.2 0 012.647-.255c5.843.121 11.685.317 17.529.414 1.967.033 4.2-.8 5.86-.126 7.156 2.9 14.2.117 21.28.064 2.062-.015 4.125-.445 6.183-.409 3.057.053 4.189 1.631 2.844 4.364-5.337 10.839-10.832 21.6-16.3 32.374-1.216 2.394-2.569 4.718-4.072 7.464 4.432-.262 8.432-.439 12.422-.756 2.24-.178 4.459-.78 6.694-.818a9.614 9.614 0 014.152 1.087c.643.311 1.418 1.6 1.226 2.1a3.149 3.149 0 01-2.126 1.775c-7.957.7-15.921 1.382-23.9 1.761-4.877.232-6.2-1.973-3.947-6.33 2.712-5.244 5.732-10.327 8.517-15.534 3.783-7.086 7.476-14.221 11.359-21.613z"
          fill="#fff"
        />
        <path
          data-name="Path 17848"
          d="M229.941 153.943c-.525 3.824-1.086 9.814-2.224 15.694-1.251 6.463-4.5 9.651-9.376 10.144-7.09.716-13.209-2.1-15.3-8.029a65.43 65.43 0 01-3.42-15.55c-.711-7.667-.607-15.412-.768-23.126-.033-1.566-.034-3.38 2.422-3.236 2.485.146 2.621 1.779 2.675 3.765.185 6.837.337 13.685.855 20.5a79.862 79.862 0 002.061 13.6c1.485 5.833 5.444 8.585 10.377 7.568 1.414-.291 3.383-1.556 3.721-2.761 1.409-5.013 2.974-10.143 3.2-15.287.306-6.922-.572-13.9-.931-20.847a10.328 10.328 0 01-.014-3.1 12.92 12.92 0 011.568-2.836c1.063.736 2.612 1.248 3.077 2.258a15.807 15.807 0 01.959 5.22c.385 4.607.654 9.216 1.118 16.023z"
          fill="#fff"
        />
        <path
          data-name="Path 17849"
          d="M74.73 137.09c-1.658-.779-3.325-1.538-4.972-2.341a9.31 9.31 0 00-13.263 7.684c-.169 2.7.769 4.7 3.271 5.626 4.377 1.621 8.845 2.991 13.248 4.544 10.029 3.537 12.555 15.56 4.68 22.721-4.547 4.135-10.163 5.172-16.072 4.258a18.365 18.365 0 01-11.148-5.532 7.642 7.642 0 01-1.854-6.793 3.3 3.3 0 012.173-2.259c.85-.063 2.526 1.091 2.572 1.8.236 3.6 1.221 6.313 5.2 7.252 5.189 1.225 10.358 1.853 14.694-1.914a11.469 11.469 0 003.636-6.016c.886-4.724-.911-7.139-5.391-8.877-4.62-1.791-9.38-3.309-13.8-5.508-5.525-2.75-7.431-6.748-6.327-11.544 1.465-6.369 5.839-10.461 11.753-10.8 3.482-.2 7.081-.269 9.841 2.3 1.226 1.144 1.914 2.864 2.846 4.323z"
          fill="#fff"
        />
        <path
          data-name="Path 17850"
          d="M33.476 135.03c-3.467 0-6.669-.08-9.865.028-2.044.07-3.641-.861-3.465-2.693.087-.916 2.219-2.3 3.453-2.329 5.361-.129 10.731.176 16.1.289 1.5.032 3-.1 4.5-.074a13.34 13.34 0 014.155.324 3.348 3.348 0 011.849 2.337c.069.626-1.112 1.95-1.809 2-2.968.227-5.963.1-9.13.1 0 4.5.026 8.886-.006 13.27-.066 9.23-.082 18.462-.355 27.687a4.257 4.257 0 01-2.383 3.124c-1.994.631-2.776-1.077-2.819-2.814-.1-4.038-.1-8.079-.077-12.118.048-8.767.145-17.533.192-26.3a24.384 24.384 0 00-.34-2.831z"
          fill="#fff"
        />
        <path
          data-name="Path 17851"
          d="M10.691 134.999c-1.273 0-2.5.284-3.485-.083-.893-.334-1.522-1.376-2.268-2.105.654-.629 1.23-1.671 1.977-1.813a75.362 75.362 0 018.346-1.043c1.838-.138 3.678.6 3.753 2.556.036.927-1.614 1.919-2.5 2.881l-.748-.421v38.783c.732 0 1.385-.2 1.772.039a30.156 30.156 0 012.779 2.128c-.894.724-1.686 1.807-2.7 2.1a33.222 33.222 0 01-6.912 1.243c-1.526.1-3.909.559-3.852-1.935.022-.948 1.88-1.854 2.9-2.779l.942.572z"
          fill="#fff"
        />
        <path
          data-name="Path 17852"
          d="M211.963 132.718c-1.534-.368-3.242-.917-2.081-2.713a91.051 91.051 0 016.912-9.069c.313-.372 1.884-.032 2.608.411a2.189 2.189 0 01.649 2.03c-2.039 2.888-4.226 5.673-6.444 8.43-.371.462-1.109.629-1.644.911z"
          fill="#fff"
        />
        <g data-name="Group 824" fill="#fff">
          <path
            data-name="Path 17853"
            d="M16.083 197.717H9.637v7.931h7.146l-.224 1.457H8.043v-19.057h8.379v1.457H9.64v6.756h6.443z"
          />
          <path
            data-name="Path 17854"
            d="M19.191 188.048h3.979c4.2 0 6.221 3.027 6.221 9 0 6.473-1.793 10.06-6.53 10.06h-3.67zm1.625 17.628h1.934c3.783 0 4.961-3.027 4.961-8.548 0-5.325-1.486-7.651-4.933-7.651h-1.957z"
          />
          <path
            data-name="Path 17855"
            d="M34.132 188.048V201.5c0 2.326.672 4.456 3.335 4.456 2.718 0 3.391-1.794 3.391-4.456v-13.452h1.6v13.48c0 3.475-1.205 5.857-5.044 5.857-3.615 0-4.933-2.241-4.933-5.689v-13.648z"
          />
          <path
            data-name="Path 17856"
            d="M55.71 201.92c-.084 1.177-.589 5.437-4.989 5.437-4.932 0-5.24-5.577-5.24-9.949 0-5.325 1.345-9.64 5.492-9.64 4.428 0 4.624 4.315 4.68 5.325H54c-.028-.645-.169-3.812-3.055-3.812-3.335 0-3.755 4.624-3.755 8.1 0 4.176.476 8.519 3.587 8.519 2.747 0 3.195-2.914 3.279-3.979z"
          />
          <path
            data-name="Path 17857"
            d="M60.361 200.603l-1.541 6.5h-1.6l4.653-19.057h2.1l4.848 19.057h-1.737l-1.569-6.5zm4.792-1.457c-1.262-5.073-2.018-8.155-2.27-9.557h-.056c-.253 1.625-1.093 5.24-2.13 9.557z"
          />
          <path
            data-name="Path 17858"
            d="M80.567 201.92c-.084 1.177-.589 5.437-4.988 5.437-4.933 0-5.241-5.577-5.241-9.949 0-5.325 1.345-9.64 5.493-9.64 4.428 0 4.624 4.315 4.68 5.325h-1.657c-.028-.645-.168-3.812-3.055-3.812-3.335 0-3.755 4.624-3.755 8.1 0 4.176.476 8.519 3.587 8.519 2.747 0 3.195-2.914 3.279-3.979z"
          />
          <path
            data-name="Path 17859"
            d="M84.993 188.048v19.057h-1.625v-19.057z"
          />
          <path
            data-name="Path 17860"
            d="M98.978 197.409c0 6.306-1.43 9.949-5.465 9.949-3.867 0-5.353-3.475-5.353-9.893 0-6.362 1.766-9.7 5.437-9.7 3.868.003 5.381 3.226 5.381 9.644zm-9.136 0c0 5.773 1.233 8.52 3.727 8.52 2.579 0 3.728-2.718 3.728-8.463 0-5.97-1.262-8.24-3.756-8.24-2.41-.001-3.699 2.633-3.699 8.183zm7.791-14.237l-3.531 3.279h-1.738l3.195-3.279z"
          />
          <path
            data-name="Path 17861"
            d="M102.143 207.105v-19.057h2.1c5.773 14.18 6.5 15.89 6.754 16.675h.028c-.14-2.662-.169-5.549-.169-8.632v-8.043h1.541v19.057h-1.989c-6-14.825-6.642-16.479-6.838-17.179h-.027c.14 2.382.14 5.633.14 9.051v8.127z"
          />
          <path
            data-name="Path 17862"
            d="M121.76 188.048h1.625v8.24h6.754v-8.24h1.6v19.057h-1.6v-9.36h-6.754v9.36h-1.625z"
          />
          <path
            data-name="Path 17863"
            d="M137.256 188.048V201.5c0 2.326.672 4.456 3.335 4.456 2.718 0 3.392-1.794 3.392-4.456v-13.452h1.6v13.48c0 3.475-1.205 5.857-5.045 5.857-3.615 0-4.932-2.241-4.932-5.689v-13.648z"
          />
          <path
            data-name="Path 17864"
            d="M160.685 198.613c0-3.671.056-7.2.112-9.136h-.032c-.728 3.167-2.69 11.659-4.316 17.628h-1.625c-1.205-5.044-3.307-14.208-4.035-17.656h-.056c.112 2.214.2 6.474.2 9.725v7.931h-1.541v-19.057h2.382c1.738 6.25 3.448 14.237 3.951 16.618h.028c.337-1.877 2.438-10.593 4.148-16.618h2.382v19.057h-1.6z"
          />
          <path
            data-name="Path 17865"
            d="M167.745 200.603l-1.541 6.5h-1.6l4.651-19.057h2.1l4.849 19.057h-1.737l-1.57-6.5zm4.792-1.457c-1.26-5.073-2.017-8.155-2.27-9.557h-.056c-.253 1.625-1.093 5.24-2.13 9.557z"
          />
          <path
            data-name="Path 17866"
            d="M178.478 207.105v-19.057h2.1c5.773 14.18 6.5 15.89 6.754 16.675h.028c-.14-2.662-.169-5.549-.169-8.632v-8.043h1.541v19.057h-1.989c-6-14.825-6.642-16.479-6.838-17.179h-.028c.14 2.382.14 5.633.14 9.051v8.127z"
          />
          <path
            data-name="Path 17867"
            d="M194.311 188.048v19.057h-1.625v-19.057z"
          />
          <path
            data-name="Path 17868"
            d="M198.654 202.229c.141 2.074 1.205 3.728 3.419 3.728 2.1 0 3.251-1.121 3.251-3.587 0-2.186-.812-3.308-3.475-4.6-2.886-1.373-4.232-2.691-4.232-5.409a4.32 4.32 0 014.568-4.567c3.447 0 4.428 2.55 4.485 4.708h-1.6c-.084-.869-.364-3.307-2.97-3.307a2.719 2.719 0 00-2.859 3.027c0 1.989.9 2.914 3.335 4.091 3.307 1.6 4.4 3.2 4.4 5.914 0 3.027-1.626 5.129-4.961 5.129-3.615 0-4.82-2.41-4.988-5.129z"
          />
          <path
            data-name="Path 17869"
            d="M212.387 189.505h-4.316v-1.457H218.3v1.457h-4.313v17.6h-1.6z"
          />
          <path
            data-name="Path 17870"
            d="M220.373 200.603l-1.541 6.5h-1.6l4.651-19.057h2.1l4.848 19.057h-1.738l-1.57-6.5zm4.792-1.457c-1.261-5.073-2.018-8.155-2.27-9.557h-.056c-.252 1.625-1.093 5.24-2.13 9.557z"
          />
        </g>
        <g data-name="Group 825" fill="#fff">
          <path
            data-name="Path 17871"
            d="M11.918 221.445a27.073 27.073 0 00-.953 6.277H9.48a21.758 21.758 0 011.177-6.277zm3.139 0a25.493 25.493 0 00-.953 6.277h-1.485a18.324 18.324 0 011.177-6.277z"
          />
          <path
            data-name="Path 17872"
            d="M23.267 236.606c.14 2.074 1.205 3.728 3.419 3.728 2.1 0 3.251-1.121 3.251-3.588 0-2.186-.812-3.307-3.475-4.6-2.887-1.373-4.232-2.691-4.232-5.409a4.32 4.32 0 014.568-4.568c3.447 0 4.428 2.551 4.484 4.708h-1.6c-.084-.869-.364-3.307-2.971-3.307a2.719 2.719 0 00-2.858 3.027c0 1.989.9 2.914 3.335 4.091 3.307 1.6 4.4 3.2 4.4 5.914 0 3.027-1.626 5.128-4.961 5.128-3.615 0-4.82-2.41-4.988-5.128z"
          />
          <path
            data-name="Path 17873"
            d="M35.429 234.645c0 3.111.561 5.689 2.971 5.689 2.438 0 2.746-2.354 2.83-2.775h1.569c-.028.337-.448 4.176-4.4 4.176-4.008 0-4.569-3.783-4.569-7.286 0-4.456 1.457-7.286 4.68-7.286 3.559 0 4.4 3.027 4.4 6.5 0 .2 0 .589-.028.981zm5.885-1.317c0-2.831-.644-4.793-2.83-4.793-2.831 0-2.971 3.84-3.027 4.793z"
          />
          <path
            data-name="Path 17874"
            d="M45.63 231.73c0-2.887-.028-3.727-.028-4.344h1.513a19.645 19.645 0 01.084 1.99 3.365 3.365 0 013.419-2.242c1.934 0 3.615 1.065 3.615 4.315v10.033h-1.6v-9.78c0-1.821-.645-3.111-2.382-3.111-2.158 0-3.027 1.457-3.027 4.625v8.267h-1.6z"
          />
          <path
            data-name="Path 17875"
            d="M55.942 227.387h2.046v-3.84h1.541v3.84h2.55v1.4h-2.55v9.809c0 1.037.224 1.71 1.317 1.71a6.056 6.056 0 001.064-.085v1.233a4.876 4.876 0 01-1.541.225c-1.43 0-2.382-.617-2.382-2.718v-10.173h-2.046z"
          />
          <path
            data-name="Path 17876"
            d="M64.181 224.303v-2.8h1.569v2.8zm0 3.083h1.569v14.1h-1.569z"
          />
          <path
            data-name="Path 17877"
            d="M69.279 232.206c0-2.242 0-3.811-.028-4.82h1.513c.028.42.056 1.093.056 2.578a4.02 4.02 0 013.756-2.8v1.737c-2.495.112-3.728 1.962-3.728 4.54v8.04h-1.569z"
          />
          <path
            data-name="Path 17878"
            d="M73.737 244.762a21.057 21.057 0 001.513-6.586h1.541a17.253 17.253 0 01-1.709 6.586z"
          />
          <path
            data-name="Path 17879"
            d="M84.806 222.426h4.624c2.943 0 4.9 1.878 4.9 5.157 0 3.307-1.765 5.492-5.212 5.492h-2.69v8.412h-1.625zm1.625 9.22h2.522c2.41 0 3.727-1.317 3.727-4.063 0-2.186-1.149-3.756-3.475-3.756H86.43z"
          />
          <path
            data-name="Path 17880"
            d="M97.388 234.645c0 3.111.561 5.689 2.971 5.689 2.438 0 2.746-2.354 2.831-2.775h1.569c-.028.337-.448 4.176-4.4 4.176-4.008 0-4.569-3.783-4.569-7.286 0-4.456 1.457-7.286 4.68-7.286 3.559 0 4.4 3.027 4.4 6.5 0 .2 0 .589-.027.981zm5.885-1.317c0-2.831-.644-4.793-2.83-4.793-2.831 0-2.971 3.84-3.027 4.793z"
          />
          <path
            data-name="Path 17881"
            d="M107.589 231.73c0-2.887-.028-3.727-.028-4.344h1.513a19.648 19.648 0 01.084 1.99 3.364 3.364 0 013.419-2.242c1.934 0 3.614 1.065 3.614 4.315v10.033h-1.6v-9.78c0-1.821-.644-3.111-2.382-3.111-2.158 0-3.027 1.457-3.027 4.625v8.267h-1.6z"
          />
          <path
            data-name="Path 17882"
            d="M120.199 237.502c.14 1.71 1.037 2.887 2.775 2.887a2.325 2.325 0 002.522-2.523c0-1.625-.84-2.354-2.775-3.111-2.747-1.065-3.615-2.074-3.615-4.007a3.472 3.472 0 013.84-3.615c2.943 0 3.84 1.99 3.867 3.9h-1.569a2.305 2.305 0 00-2.354-2.522 1.989 1.989 0 00-2.185 2.185c0 1.205.5 1.822 2.55 2.606 2.606 1.037 3.867 1.99 3.867 4.456 0 2.494-1.569 3.979-4.26 3.979-2.718 0-4.147-1.653-4.232-4.232z"
          />
          <path
            data-name="Path 17883"
            d="M137.462 239.213a19.6 19.6 0 00.112 2.27h-1.457a9.368 9.368 0 01-.14-1.709 3.309 3.309 0 01-3.391 1.962c-2.718 0-3.67-2.1-3.67-4.036 0-2.466 1.653-4.456 5.408-4.456.728 0 1.149 0 1.6.028v-1.991c0-1.205-.252-2.774-2.354-2.774-2.354 0-2.466 1.709-2.55 2.662h-1.545c.056-1.99 1.008-4.008 4.12-4.008 2.634 0 3.867 1.289 3.867 3.867zm-1.513-4.6a9.966 9.966 0 00-1.541-.084c-2.747 0-3.9 1.346-3.9 3.055 0 1.485.785 2.747 2.41 2.747 2.214 0 3.027-1.514 3.027-4.821z"
          />
          <path
            data-name="Path 17884"
            d="M140.992 232.206c0-2.242 0-3.811-.028-4.82h1.517c.028.42.057 1.093.057 2.578a4.019 4.019 0 013.755-2.8v1.737c-2.494.112-3.727 1.962-3.727 4.54v8.04h-1.57z"
          />
          <path
            data-name="Path 17885"
            d="M153.994 227.386c1.709 6.418 2.943 10.789 3.139 12.218h.028c.253-1.485 1.654-6.585 3.139-12.218h1.653l-4.148 14.824c-1.121 4.036-2.017 4.708-4.035 4.708a4.93 4.93 0 01-.925-.084v-1.457a4.052 4.052 0 00.9.085c1.57 0 1.99-1.15 2.607-3.643l-4.092-14.433z"
          />
          <path
            data-name="Path 17886"
            d="M177.558 237.363c-.056.588-.392 4.372-4.315 4.372-3.531 0-4.541-3.251-4.541-7.314s1.318-7.286 4.737-7.286c3.9 0 4.12 3.727 4.12 4.315h-1.625c-.028-.588-.28-2.915-2.494-2.915-2.27 0-3.111 2.466-3.111 5.858 0 3.671.9 5.941 3 5.941 2.214 0 2.522-2.243 2.635-2.971z"
          />
          <path
            data-name="Path 17887"
            d="M180.139 232.206c0-2.242 0-3.811-.028-4.82h1.513c.028.42.057 1.093.057 2.578a4.019 4.019 0 013.755-2.8v1.737c-2.494.112-3.727 1.962-3.727 4.54v8.04h-1.57z"
          />
          <path
            data-name="Path 17888"
            d="M188.126 234.645c0 3.111.561 5.689 2.971 5.689 2.438 0 2.747-2.354 2.831-2.775h1.569c-.028.337-.449 4.176-4.4 4.176-4.008 0-4.568-3.783-4.568-7.286 0-4.456 1.457-7.286 4.68-7.286 3.559 0 4.4 3.027 4.4 6.5 0 .2 0 .589-.028.981zm5.886-1.317c0-2.831-.644-4.793-2.831-4.793-2.83 0-2.97 3.84-3.027 4.793z"
          />
          <path
            data-name="Path 17889"
            d="M205.949 239.213a19.6 19.6 0 00.112 2.27h-1.457a9.316 9.316 0 01-.14-1.709 3.309 3.309 0 01-3.391 1.962c-2.718 0-3.671-2.1-3.671-4.036 0-2.466 1.653-4.456 5.409-4.456.728 0 1.149 0 1.6.028v-1.991c0-1.205-.252-2.774-2.354-2.774-2.354 0-2.466 1.709-2.55 2.662h-1.541c.056-1.99 1.008-4.008 4.12-4.008 2.634 0 3.867 1.289 3.867 3.867zm-1.513-4.6a9.971 9.971 0 00-1.541-.084c-2.747 0-3.9 1.346-3.9 3.055 0 1.485.785 2.747 2.41 2.747 2.214 0 3.027-1.514 3.027-4.821z"
          />
          <path
            data-name="Path 17890"
            d="M209.48 232.206c0-2.242 0-3.811-.028-4.82h1.513c.028.42.057 1.093.057 2.578a4.018 4.018 0 013.755-2.8v1.737c-2.495.112-3.728 1.962-3.728 4.54v8.04h-1.569z"
          />
          <path
            data-name="Path 17891"
            d="M220.857 227.722a24.178 24.178 0 00.98-6.277h1.458a17.825 17.825 0 01-1.177 6.277zm3.138 0a27 27 0 00.982-6.277h1.457a20.852 20.852 0 01-1.177 6.277z"
          />
        </g>
        <g data-name="Group 826">
          <path
            data-name="Rectangle 299"
            fill="#e04030"
            d="M7.938 210.031h55.381v6.005H7.938z"
          />
          <path
            data-name="Rectangle 300"
            fill="#3a6844"
            d="M63.541 210.031h55.381v6.005H63.541z"
          />
          <path
            data-name="Rectangle 301"
            fill="#fac835"
            d="M118.922 210.031h55.381v6.005h-55.381z"
          />
          <path
            data-name="Rectangle 302"
            fill="#28328c"
            d="M174.525 210.031h55.381v6.005h-55.381z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgEscuela;
