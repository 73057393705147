import React, {useContext, useEffect } from "react";
import { Link } from "react-scroll";

import { types } from "../context/Reducer";
import { AppContext } from "../context/Context";

export const MobileMenu = (props) => {
    const [state, dispatch] = useContext(AppContext);

    useEffect(()=>{},[state])

    function handleMobileMenu(){
      dispatch({ type: types.toggleMenu})
    }
  
  return (
    <div className="mobile-menu">
      <Link
        to="home"
        smooth={true}
        duration={1000}
        activeClass={"active"}
        spy={true}
      >
        <span className="menu__text" onClick={() =>  handleMobileMenu()}>Home</span>
      </Link>
      <Link
        to="about"
        smooth={true}
        duration={1000}
        activeClass={"active"}
        spy={true}
      >
        <span className="menu__text" onClick={() =>  handleMobileMenu()}>About</span>
      </Link>
      <Link
        to="work"
        smooth={true}
        duration={1000}
        activeClass={"active"}
        spy={true}
      >
        <span className="menu__text" onClick={() =>  handleMobileMenu()}>Work</span>
      </Link>
      <Link
        to="clients"
        smooth={true}
        duration={1000}
        activeClass={"active"}
        spy={true}
      >
        <span className="menu__text" onClick={() =>  handleMobileMenu()}>Clients</span>
      </Link>
      <Link
        to="contact"
        smooth={true}
        duration={1000}
        activeClass={"active"}
        spy={true}
      >
        <span className="menu__text" onClick={() =>  handleMobileMenu()}>Contact</span>
      </Link>
    </div>
  );
};
