import React, { createContext, useReducer } from "react";
import { initialState, Reducer } from "./Reducer";

export const AppContext = createContext(initialState);

export const ContextProvider = ({ children }) =>
(
  <AppContext.Provider value={useReducer(Reducer, initialState)} >
    {children}
  </AppContext.Provider>
);
