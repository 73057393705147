import * as React from "react";

function SvgHome(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51.3 51.3"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M45.489 0h-39.7c-3.2 0-5.8 2.6-5.8 5.8v39.7c0 3.2 2.6 5.8 5.8 5.8h39.7c3.2 0 5.8-2.6 5.8-5.8V5.8c0-3.2-2.6-5.8-5.8-5.8zm-7.9 34.9c0 1.5-1.2 2.7-2.7 2.7h-18.5c-1.5 0-2.7-1.2-2.7-2.7V16.4c0-1.5 1.2-2.7 2.7-2.7h18.4c1.5 0 2.7 1.2 2.7 2.7v18.5z"
        fill="#474748"
      />
    </svg>
  );
}

export default SvgHome;
