import React, { useContext } from 'react'

import { AppContext } from "../context/Context";


export const PortfolioItem = ({src}) => {
   const [state] = useContext(AppContext);

   console.log(src);
    
    return (
        
        <div className={`portfolio__item ${`active`}`}>
            <img alt="porfolio-item" className="portfolio__item--image" src={src}/>
        </div>
    )
}
