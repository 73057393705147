import brandingPic_01 from '../images/portfolio/branding/branding-01.svg'
import brandingPic_02 from '../images/portfolio/branding/branding-02.svg'
import brandingPic_03 from '../images/portfolio/branding/branding-03.svg'
import brandingPic_04 from '../images/portfolio/branding/branding-04.svg'

import uxPic01 from '../images/portfolio/ux/ux-01.svg'
import uxPic02 from '../images/portfolio/ux/ux-02.svg'
import uxPic03 from '../images/portfolio/ux/ux-03.svg'
import uxPic04 from '../images/portfolio/ux/ux-04.svg'






export const dataBytes = [
  {
    texts: {
      pOne: "We are a small digital creative studio, we imagine the impossible, and we create it, and thus our client will stand out from the crowd. We have more than 12 years in the market, we have specialized in web development, also work from brand identity designs and analytics. We love working closely with our clients, knowing their needs, whether it is a young or a established company. Together we can create a brand that you will love.",
      tabColors: ["blue"],
      title: ["Brand"],
      subtitle: ["ing"],
      workDescription: [ 
      "We see the future, that is how the brand is made.",
      ]
    },
  }
];

export const images = [

    brandingPic_01,
     brandingPic_02,
    brandingPic_03,
      brandingPic_04,
      uxPic01,
      uxPic02,
      uxPic03,
      uxPic04
   
  ]

 