import  React, { useContext }  from 'react'
import { SubTextComponent } from './SubTextComponent'
import { WorkList } from './WorkList'
import { Work as WorkIcon } from '../svgComponents';

import { AppContext } from "../context/Context";

export const Work=(props)=>{
    const [state] = useContext(AppContext);
    
    return(
        <section id="work" className="work">
            <div className="mobile-selector">
                <span className="mobile-selector__icon">
                    <WorkIcon/>
                </span>
                <h2>Work</h2>
            </div>
            <div className={`work__text ${state.activeObj != null ? `unactive` : `active`}`}>
                <SubTextComponent offset={props.offSetApp} titulo="What" tituloBreak=" are" subtitulo="You looking for?"/>
            </div>
            <WorkList/>
        </section>
    )
}
