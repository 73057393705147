import React   from 'react'
import {SubTextComponent} from './SubTextComponent'
import {dataBytes} from '../data/data'
import { About as AboutIcon } from '../svgComponents'

export const About = (props)=>{
    
    const [data] = [...dataBytes]
    

    return(
        <section id="about" className="about">
            <div className="mobile-selector">
                <span className="mobile-selector__icon">
                    <AboutIcon/>
                </span>
                <h2>About</h2>
            </div>
            <SubTextComponent offset={props.offSetApp} titulo="This is" tituloBreak=" Us." subtitulo="Always ready to help"/>
            <p className="paragraph">{data.texts.pOne}</p>
         </section>
    )
}