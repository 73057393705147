import * as React from "react";

function SvgClient(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51.3 51.3"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M45.489.003h-39.7c-3.2 0-5.8 2.6-5.8 5.8v39.7c0 3.2 2.6 5.8 5.8 5.8h39.7c3.2 0 5.8-2.6 5.8-5.8v-39.7c0-3.2-2.6-5.8-5.8-5.8zm-11.1 39.1h-17.5l-5.3-16.6 14.1-10.2 14.1 10.2-5.4 16.6z"
        fill="#474748"
      />
    </svg>
  );
}

export default SvgClient;
