import * as React from "react";

function SvgWork(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51.3 51.3"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M-.01 5.811v39.7c0 3.2 2.6 5.8 5.8 5.8h39.7c3.2 0 5.8-2.6 5.8-5.8v-39.7c0-3.2-2.6-5.8-5.8-5.8H5.79c-3.2 0-5.8 2.6-5.8 5.8zm36.5 36.9l-23.1-17.1 23.1-17.1v34.2z"
        fill="#474748"
      />
    </svg>
  );
}

export default SvgWork;
