export const types = {
    active: 'I_AM_ACTIVE',
    unactive: 'I_AM_UNACTIVE',
    toggleMenu: 'TOGGLE_MENU'
}

export const initialState = {
    activeObj: null,
    objects: [
        { id: 1 }
        
        
    ],
    mobileMenuState: false,
};


export const Reducer = (state, action) => {
    switch (action.type) {
        case types.active:
            return { ...state, activeObj: state.objects[action.payload], index: action.payload }
        case types.unactive:
            return { ...state, activeObj: null }
        case types.toggleMenu:
            return { ...state, mobileMenuState: !state.mobileMenuState }
        default:
            return state
    }
}