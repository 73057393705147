import * as React from "react";

function SvgLogo(props) {
  return (
    <svg
      id="logo_svg__Logo"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 122 52"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      {...props}
    >
      <style>{".logo_svg__st0{fill:#fff}"}</style>
      <path
        id="logo_svg__Path_1"
        className="logo_svg__st0"
        d="M60.8 32.3c-1.5-.4-2-.6-2-1.2s.6-1 1.5-1c1 0 2 .4 2.8 1 .1.1.2.1.3.1.1 0 .2-.1.3-.2l.9-1.5c.1-.1.1-.2.1-.3 0-.1-.1-.3-.2-.3-1.2-1-2.7-1.5-4.2-1.5-2.7 0-4.6 1.4-4.6 3.9 0 2.1 1.3 3.1 3.8 3.7 1.7.4 2.2.6 2.2 1.3 0 .6-.6 1-1.7 1s-2.1-.4-3-1.1c-.1-.2-.2-.2-.4-.2-.1 0-.2.1-.3.3l-.9 1.4c-.1.1-.1.2-.1.4s.1.3.3.4c1.3 1 2.8 1.5 4.4 1.5 2.9 0 4.8-1.5 4.8-3.9 0-2.1-1.3-3.2-4-3.8z"
      />
      <path
        id="logo_svg__Path_2"
        className="logo_svg__st0"
        d="M99.3 23.7v-2.3h-4.9c-.2 0-.3-.1-.3-.3v-1.4c0-.2.1-.3.3-.3h3.9c.3 0 .5-.2.5-.5v-2.3h-4.6V15c0-.2.1-.3.3-.3h4.9v-2.3c0-.2-.1-.4-.4-.5H91.6c-.3 0-.5.1-.5.5v11.7h7.7c.2 0 .4-.1.5-.3v-.1z"
      />
      <path
        id="logo_svg__Path_3"
        className="logo_svg__st0"
        d="M77.7 27.6c-.3 0-.5.1-.5.5v6.5c0 3.7 2.2 5.4 5.4 5.4 3.3 0 5.6-1.9 5.6-5.5v-6.9h-2.6c-.3 0-.4.1-.4.5v6.2c0 1.9-.9 2.9-2.5 2.9s-2.5-1-2.5-2.9v-6.7h-2.5z"
      />
      <path
        id="logo_svg__Path_4"
        className="logo_svg__st0"
        d="M83 24.2h2.6c.3 0 .5-.2.5-.5v-8.9h3c.3 0 .5-.2.5-.5V12H80c-.3 0-.5.1-.5.5v2.3H83v9.4z"
      />
      <path
        id="logo_svg__Path_5"
        className="logo_svg__st0"
        d="M46.1 0H5.9C2.6 0 0 2.6 0 5.9v40.2C0 49.3 2.6 52 5.9 52h40.2c3.2 0 5.9-2.6 5.9-5.9V5.9C52 2.6 49.3 0 46.1 0zM13.4 16l13.1 9.9-13.1 9.9V16zm24.9 17.3c-.3 5.5-3.1 9.7-8.6 9.7H19s-4.3.2-5.5-5.7l14.6-11.5-14.6-11.3V8.7h14.1c5.1 0 9.4 4 9.7 9.1.1 2.6-.8 5-2.6 6.9l-.1.1c2.5 2.1 3.9 5.2 3.7 8.5z"
      />
      <path
        id="logo_svg__Path_6"
        className="logo_svg__st0"
        d="M71.3 19.8v4.4h2.6c.3 0 .5-.2.5-.5v-4l4.3-7.1c.1-.1.1-.2.1-.3 0-.2-.1-.2-.4-.2H76c-.4-.1-.7.1-.8.5l-2.4 4.2-2.4-4.2c-.1-.4-.4-.6-.8-.6h-2.4c-.2 0-.4.1-.4.2s0 .2.1.3l4.4 7.3z"
      />
      <path
        id="logo_svg__Path_7"
        className="logo_svg__st0"
        d="M31.6 29.3c-.8-.5-1.7-.7-2.6-.7h-2.2L19 34.9v2.5h10.8c1.9 0 3.5-1.6 3.6-3.5.4-1.9-.3-3.6-1.8-4.6z"
      />
      <path
        id="logo_svg__Path_8"
        className="logo_svg__st0"
        d="M30.2 21.5c1.3-1.8 1.2-4.2-.4-5.8l-.2-.2c-.8-.7-1.8-1-2.8-1H19V17l8.1 6.3c1.2-.1 2.4-.7 3.1-1.8z"
      />
      <path
        id="logo_svg__Path_9"
        className="logo_svg__st0"
        d="M95.5 27.6h-4.3c-.3 0-.5.1-.5.5v11.7h4.4c4.4 0 6.9-2.4 6.9-6.2.1-3.7-2.3-6-6.5-6zm-.3 9.3H94c-.2 0-.3-.1-.3-.3v-6.1c0-.2.1-.3.3-.3h1.2c2.4 0 3.8 1.3 3.8 3.2 0 2.3-1.3 3.5-3.8 3.5z"
      />
      <path
        id="logo_svg__Path_10"
        className="logo_svg__st0"
        d="M104.1 28v11.7h3V27.6h-2.6c-.3 0-.4.1-.4.4z"
      />
      <path
        id="logo_svg__Path_11"
        className="logo_svg__st0"
        d="M105.9 16.7c-1.5-.4-2-.6-2-1.2s.6-1 1.5-1c1 0 2 .4 2.8 1 .1.1.2.1.3.1.1 0 .2-.1.3-.2l.9-1.5c.1-.1.1-.2.1-.3 0-.1-.1-.3-.2-.3-1.2-1-2.7-1.5-4.2-1.5-2.7 0-4.6 1.4-4.6 3.9 0 2.1 1.3 3.1 3.8 3.7 1.7.4 2.2.6 2.2 1.3 0 .6-.6 1-1.7 1s-2.1-.4-3-1.1c-.1-.1-.2-.1-.3-.2-.1 0-.2.1-.3.3l-.9 1.4c-.1.1-.1.2-.1.4s.1.3.3.4c1.3 1 2.8 1.5 4.4 1.5 2.9 0 4.8-1.5 4.8-3.9-.2-2.1-1.4-3.1-4.1-3.8z"
      />
      <path
        id="logo_svg__Path_12"
        className="logo_svg__st0"
        d="M115.5 27.3c-3.4-.2-6.3 2.5-6.5 5.9v.4c-.1 3.4 2.6 6.2 6 6.3h.4c3.4.2 6.3-2.4 6.5-5.9v-.4c.1-3.4-2.6-6.2-6-6.3h-.4zm0 9.9a3.4 3.4 0 01-3.4-3.2v-.3c-.1-1.8 1.3-3.4 3.1-3.5h.3c1.8 0 3.4 1.4 3.4 3.3v.3c.1 1.9-1.4 3.4-3.2 3.5-.1-.1-.2-.1-.2-.1z"
      />
      <path
        id="logo_svg__Path_13"
        className="logo_svg__st0"
        d="M64.4 17.9c1-.4 1.6-1.4 1.5-2.5 0-2.4-1.6-3.4-4.6-3.4h-4.5c-.3 0-.5.1-.5.5v11.7h5c3.1 0 4.9-1.1 4.9-3.7.1-1.2-.7-2.3-1.8-2.6zm-5.1-3c0-.2.1-.3.3-.3h1.5c1.2 0 1.7.3 1.7 1s-.5 1-1.7 1h-1.5c-.2 0-.3-.1-.3-.3v-1.4zm2 6.6h-1.8c-.2 0-.3-.1-.3-.3v-1.9h2c1.2 0 1.8.3 1.8 1.1s-.4 1.1-1.7 1.1z"
      />
      <path
        id="logo_svg__Path_14"
        className="logo_svg__st0"
        d="M65.9 28v2.3h3.4v9.4h2.6c.3 0 .5-.2.5-.5v-8.9h3c.3 0 .5-.2.5-.5v-2.3h-9.5c-.4.1-.5.2-.5.5z"
      />
    </svg>
  );
}

export default SvgLogo;
