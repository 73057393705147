import * as React from "react";

function SvgContact(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51.3 51.3"
      width="1em"
      height="1em"
      {...props}
    >
      <path d="M45.489.005h-39.7c-3.2 0-5.8 2.6-5.8 5.8v39.7c0 3.2 2.6 5.8 5.8 5.8h39.7c3.2 0 5.8-2.6 5.8-5.8v-39.7c0-3.2-2.6-5.8-5.8-5.8zm-19.8 38c-6.8 0-12.4-5.5-12.4-12.4s5.5-12.4 12.4-12.4 12.4 5.5 12.4 12.4c-.1 6.9-5.6 12.4-12.4 12.4z" />
    </svg>
  );
}

export default SvgContact;
