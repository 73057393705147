import React, { useContext, useEffect, useState, useCallback } from "react";


import { AppContext } from "../context/Context";
import { types } from "../context/Reducer";
import { PortfolioList } from "./PortfolioList";
import {WorkDetail} from './WorkDetail';

import { dataBytes, images } from "../data/data";

export const WorkList = () => {
  const [state, dispatch] = useContext(AppContext);
  const [data] = [...dataBytes];
  const [imagesSelected] = [images];

  const [workImages, setWorkImages] = useState(null);

  const [portfolioImages, setPortfolioImages] = useState(<></>);

  const selectedColor = useCallback(
    (color) => {
      if (state.activeObj === null) {
        return ``;
      }
      if (state.activeObj.id === 1) {
        return `active-light-blue`;
      }
     
    },
    [state]
  );

  const activeTab = useCallback(
    (index) => {
      const classesHover = {
        active: "active",
        unactive: "unactive",
      };
      if (state.activeObj === null) {
        return ``;
      }
      if (state.objects[index] === state.activeObj) {
        return classesHover.unactive;
      }
      return classesHover.unactive;
    },
    [state.objects, state.activeObj]
  );

  const renderImages = useCallback(() => {
   // debugger
    if (state.activeObj === null) {
      return <></>;
    }
    if (state.activeObj.id === 1) {
      return (
        <PortfolioList
          propLoop={true}
          images={workImages}
          numberColumns={2}
          numberSlides={3}
          setSpaceBetween={5}
          breakPoints={
            {
              280: {       
                slidesPerView: 1.55,
                spaceBetween: 0,
                slidesPerColumn: 1,
                longSwipesRatio: 1,
                autoHeight: true
             },
							320: {       
							   slidesPerView: 1.8,
							   spaceBetween: 0,
                 slidesPerColumn: 1,
                 longSwipesRatio: 1,
                 autoHeight: true
							},        
							480: {       
							   slidesPerView: 2.5,       
							   spaceBetween: 0, 
                 slidesPerColumn: 1,
                 longSwipesRatio: 1,
                 autoHeight: true
							},       
							768: {       
							   slidesPerView: 3.5,       
							   spaceBetween: 0,
                 slidesPerColumn: 1,
                 longSwipesRatio: 1
							}, 
							1440: {       
								slidesPerView: 2.5,       
								spaceBetween: 0,
                slidesPerColumn: 3,
							 },
							 1600: {       
								slidesPerView: 2.09,       
								spaceBetween: 0,
                slidesPerColumn: 3, 
							 } ,
							 1900: {       
								slidesPerView: 3.5,       
								spaceBetween: 0,
                slidesPerColumn: 3, 
							 },
               2200: {       
								slidesPerView: 2.9,       
								spaceBetween: 0,
                slidesPerColumn: 3, 
							 } 
						
						 } 
          }
        />
      );
    }
  }, [state, workImages]);

  useEffect(() => {
    activeTab(null);
    setPortfolioImages(renderImages());
  }, [state, activeTab, renderImages]);

  useEffect(() => {
    if (state.activeObj === null) {
      setWorkImages(null);
    }
    if (state.activeObj != null) {
      if (state.activeObj.id === 1) {
        setWorkImages(imagesSelected[0]);
        console.log(workImages)
      }
     
    
    }
  }, [setWorkImages, state, imagesSelected, workImages]);

  const setOpenedTab = (index) => {
    
    if (state.activeObj === state.objects[index]) {
      dispatch({ type: types.unactive });
    } else {
      dispatch({ type: types.active, payload: index });
    }
  };

  const handleClickTab = (index) => {
    dispatch({ type: types.unactive });
    setTimeout(() => {
      setOpenedTab(index);
    }, 1);
  };

  return (
    <div className={`work-list`}>
      <div className="work-list__tabs">
      <div className={`tab ${`unactive`}`} >
            
        </div>
        
      </div>
      <div
        className={`work-list__portfolio ${ `active`
          }`}
      >
        <PortfolioList
            propLoop={true}
            numberColumns={2}
            numberSlides={2}
            setSpaceBetween={0}
            breakPoints={
              {
                280: {
                  slidesPerView: 1.55,
                  spaceBetween: 0,
                  slidesPerColumn: 1,
                  autoHeight: true
                },
                320: {
                  slidesPerView: 1.8,
                  spaceBetween: 0,
                  slidesPerColumn: 1,
                  autoHeight: true
                },
                480: {
                  slidesPerView: 2.88,
                  spaceBetween: 0,
                  slidesPerColumn: 1,
                  autoHeight: true
                },
                768: {
                  slidesPerView: 3.5,
                  spaceBetween: 0,
                  slidesPerColumn: 1
                },
                1440: {
                  slidesPerView: 1.55,
                  spaceBetween: 0,
                  slidesPerColumn: 2
                },
                1600: {
                  slidesPerView: 2.1,
                  spaceBetween: 0,
                  slidesPerColumn: 2
                }
              }
            }
          />
      </div>
    </div>
  );
};
