import React, {useContext, useState, useEffect, useCallback}   from 'react';
import {PortfolioItem} from './PortfolioItem'

import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, { Navigation, A11y, Pagination} from 'swiper';

import { AppContext } from "../context/Context";

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

import {images} from "../data/data";


export const PortfolioList = ({propLoop, 
    numberColumns, 
    numberSlides, 
    setSpaceBetween, 
    breakPoints}) => {

    SwiperCore.use([Navigation, A11y,Pagination]);

    const [imagesSelected] = [images];

    const [state] = useContext(AppContext);
    const [colorClass, setColorClass] = useState("")

    const setColor = useCallback(()=>{
        if (state.activeObj === null) {
            return 
          }
          if (state.activeObj.id === 1) {
            setColorClass(`ux`)
          }
         

    }, [state])

    useEffect(()=>{
        setColor()
    },[state, setColor])
 
  
    if(images === null){
        return(
            <>
            </>
        )
    }else{
        
        const [workImages] = [imagesSelected];
        
        workImages.map((e,index)=>{

            console.log("Fila: "+index+" src: "+e.toString());
        });
       // debugger
        return(
            <div className={`portfolio__list ${colorClass}`}>
                <Swiper
                slidesPerView={numberSlides} 
                slidesPerColumn={numberColumns} 
                spaceBetween={setSpaceBetween} 
                slidesPerColumnFill={'row'}
                loop={false}
                initialSlide={2}
                navigation={true}
                breakpoints={breakPoints}
                freeMode={true}>
                {workImages.map((e, index)=>(
                    
                    <SwiperSlide key={index*2}>
                        <PortfolioItem key={index} src={e.toString()}></PortfolioItem>
                    </SwiperSlide>
                ))}
                </Swiper>
            </div>
        )
    }
    
}
