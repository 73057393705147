import React, { useContext }  from 'react'

import {AppContext} from '../context/Context'

export const SubTextComponent = (props)=>{
    const {offSetY} = useContext(AppContext)

    return(
        <div className="subtext-component">
            <div className="titulo">
                <span className="subtext-even" style={{transform:`translateY(${offSetY * 0.008}px)`}}>{props.titulo}&nbsp;</span>
                <span className="subtext-odd" style={{transform:`rotate(-${offSetY * 0.0015}deg)`}}><small>{props.tituloBreak}</small></span>
            </div>
            <div style={{transform:`translateY(${offSetY * 0.01}px)`}} className="subtitulo">
                {props.subtitulo}
            </div>
        </div>
    )
}