import React  from 'react'

export const TextComponent = (props)=>{
   
    return(
        <div className="text-component">
            <span style={{transform:`translateY(${props.offset * 0.03}px)`}} className="titulo">{props.titulo}</span>
            <span style={{transform:`rotate(-${props.offset * 0.003}deg)`}} className="tituloBreak">{props.tituloBreak}</span>
            <span style={{transform:`translateY(${props.offset * 0.01}px)`}} className="subtitulo">{props.subtitulo}</span>
            <span style={{transform:`rotate(${props.offset * 0.003}deg)`}} className="subtituloBreak">{props.subtituloBreak}</span>
        </div>
    )
}