import React, { useRef, useEffect, useContext } from "react";


import { AppContext } from "../context/Context";
import { types } from "../context/Reducer";

function useOutsideAlerter(ref) {
const [state, dispatch] = useContext(AppContext);
  useEffect(() => {
  
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch({ type: types.unactive })
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, dispatch, state]);
}


export function OutsideAlerter(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return <div ref={wrapperRef}>{props.children}</div>;
}


