import * as React from "react";

function SvgPalenzuela(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 324.518 219.356"
      {...props}
    >
      <defs>
        <linearGradient
          id="Palenzuela_svg__a"
          y1={0.5}
          x2={1}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#dba138" />
          <stop offset={0.098} stopColor="#e7c463" />
          <stop offset={0.591} stopColor="#e7c463" />
          <stop offset={0.917} stopColor="#dba138" />
          <stop offset={1} stopColor="#e7c463" />
        </linearGradient>
        <linearGradient
          id="Palenzuela_svg__b"
          y1={0.5}
          x2={1}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#dba138" />
          <stop offset={0.3} stopColor="#e7c463" />
          <stop offset={0.626} stopColor="#dba138" />
          <stop offset={1} stopColor="#e7c463" />
        </linearGradient>
      </defs>
      <g data-name="Group 823">
        <g data-name={2}>
          <g data-name="Group 819" fill="#eac765">
            <path
              data-name="Path 17806"
              d="M16.86 151.621a6.143 6.143 0 012.336 1.795 1.868 1.868 0 01.569 1.367.824.824 0 01-.284.684.978.978 0 01-.456.171q-.57 0-1.652-1.2a3.32 3.32 0 00-2.735-1.2 5.627 5.627 0 00-1.88.342 5.511 5.511 0 00-2.365 2.022 5.348 5.348 0 00-.94 3.049 6.8 6.8 0 00.627 2.819 10.688 10.688 0 001.652 2.593 14.024 14.024 0 001.994 1.852q1.083.826 2.137 1.509t1.453.969a22.039 22.039 0 012.934 2.422 9.137 9.137 0 011.909 2.763 8.057 8.057 0 01.8 3.646 10.261 10.261 0 01-1.766 5.755 11.63 11.63 0 01-3.333 3.333 13.666 13.666 0 01-4.416 1.908 11.79 11.79 0 01-3.133.456 8.2 8.2 0 01-3.817-.911 7 7 0 01-2.764-2.707 7.491 7.491 0 010-7.663 6.842 6.842 0 012.821-2.734 1.772 1.772 0 01.74-.229.921.921 0 01.4.115.789.789 0 01.4.741 1.441 1.441 0 01-.285.854 2.688 2.688 0 01-.684.57 8.021 8.021 0 00-.74.512 4.048 4.048 0 00-1.083 1.425 4.256 4.256 0 00-.4 1.823 4.18 4.18 0 00.912 2.791 4.39 4.39 0 003.646 1.653 4.953 4.953 0 002.336-.57 11.788 11.788 0 004.017-2.82 4.994 4.994 0 001.339-3.276 5.088 5.088 0 00-1.054-2.962 11.75 11.75 0 00-3.1-2.85 19.251 19.251 0 01-5.076-5.003 11.949 11.949 0 01-.684-12.049 8.038 8.038 0 013.647-3.675 7.008 7.008 0 013.076-.684 7.181 7.181 0 012.902.594z"
            />
            <path
              data-name="Path 17807"
              d="M32.869 168.286v.114a.436.436 0 01-.142.314.394.394 0 01-.256.142l-.114-.057a1.765 1.765 0 00-1.026-.57.63.63 0 00-.4.228q-.228.228-.512.57a6.557 6.557 0 00-1.083 2.9 17.738 17.738 0 00-.171 2.394q0 1.026.228 3.305a2.994 2.994 0 00.855 1.88q.682.684 1.025.683a1.643 1.643 0 00.8-.342 6.415 6.415 0 001.6-2.621c-.076-.456-.152-1.176-.228-2.165l-.171-2.108a15.186 15.186 0 01-.057-1.538q0-.8.115-2.507l.056-1.253a1.122 1.122 0 01.342-.912.616.616 0 01.456-.171q.057 0 .741.171a3.331 3.331 0 011.994 1.425 3.584 3.584 0 01.285 1.652v2.62a19.624 19.624 0 00.285 3.647 3.149 3.149 0 001.082 2.336 2.87 2.87 0 001.482.627 2.2 2.2 0 001.367-.627 4.252 4.252 0 001.225-2.165 15.805 15.805 0 00.4-2.393q.085-1.081.142-1.481a.827.827 0 01.256-.513.719.719 0 01.485-.171c.417 0 .627.285.627.854a17.052 17.052 0 01-.838 4.681 6.093 6.093 0 01-2.649 3.818 5.648 5.648 0 01-3.019.968 3.424 3.424 0 01-1.653-.4 4.514 4.514 0 01-2.165-2.394l-.456.627a6.993 6.993 0 01-3.7 2.451 4.749 4.749 0 01-.855.056 4.327 4.327 0 01-1.794-.4 3.49 3.49 0 01-1.4-1.083 7.5 7.5 0 01-1.42-4.843 11.585 11.585 0 01.8-4.187 11.442 11.442 0 012.336-3.733 4.494 4.494 0 012.906-1.538h.169q1.138 0 2.05 1.709z"
            />
            <path
              data-name="Path 17808"
              d="M48.309 151.878h.057a4.548 4.548 0 011.6 1.168 4.494 4.494 0 01.969 1.737 8.469 8.469 0 01.456 2.678 11.992 11.992 0 01-.114 1.31 13.494 13.494 0 01-3.59 7.578q-.228 2.508-.228 5.641v1.766a9.817 9.817 0 012.279-2.393 4.544 4.544 0 012.735-.969 4.471 4.471 0 012.678.969q1.366 1.026 1.6 3.761a3.855 3.855 0 01.056.8 4.808 4.808 0 01-.056.854 25.376 25.376 0 002.392-2.221 15.4 15.4 0 001.881-2.336.946.946 0 01.74-.456.453.453 0 01.513.512 1.348 1.348 0 01-.171.571q-1.026 2.164-5.47 5.583a38 38 0 01-.228 1.424 8.434 8.434 0 01-1.31 3.476 4.086 4.086 0 01-1.367 1.14 3.518 3.518 0 01-1.653.455.987.987 0 01-.4-.057q-1.825-.285-1.823-1.709a2.343 2.343 0 01.939-2.165 5.613 5.613 0 00.541-.456l1.71-1.14a17.8 17.8 0 00.274-2.795 19.952 19.952 0 00-.171-2.449 6.346 6.346 0 00-.285-1.6 1.244 1.244 0 00-1.14-.74 3.674 3.674 0 00-2.221 1.054 7.4 7.4 0 00-1.938 2.478l.171 2.336a8.056 8.056 0 00.57 2.28 3.53 3.53 0 01.285 1.709q0 1.937-1.368 1.937a3.522 3.522 0 01-1.766-.343 3.893 3.893 0 01-1.2-1.48A14.478 14.478 0 0142.945 177a53.827 53.827 0 01-.4-6.781 56.565 56.565 0 012.222-15.782 4.577 4.577 0 01.8-1.823 2.017 2.017 0 011.424-.8 3.112 3.112 0 01.684-.056 1.422 1.422 0 01.634.12zm1.453 9.087a9.883 9.883 0 00.655-3.5 4.463 4.463 0 00-.684-2.62 13.6 13.6 0 01-.4 1.823 61.011 61.011 0 00-1.425 7.406 10.05 10.05 0 001.854-3.109zm2.108 22.106q.312-.542.484-.883l.285-1.083-.684.514a2.515 2.515 0 00-.968.911.794.794 0 00-.057.342.718.718 0 00.4.741h.058c.112 0 .273-.178.482-.542z"
            />
            <path
              data-name="Path 17809"
              d="M68.25 167.005a6.043 6.043 0 01.57 1.851c.038.3-.019.456-.171.456a.332.332 0 01-.228-.057 2.733 2.733 0 01-.256-.456.641.641 0 00-.6-.4 1.558 1.558 0 00-.969.571 4.492 4.492 0 00-.8 1.224 9.546 9.546 0 00-.513 1.624 15.189 15.189 0 00-.456 3.818c0 .8.018 1.4.057 1.823a2.852 2.852 0 00.626 1.994 2.234 2.234 0 001.653.626 1.942 1.942 0 001.139-.284 4.844 4.844 0 001.254-1.254 3.866 3.866 0 00.8-1.653 3.773 3.773 0 01-1.681-1.31 4.57 4.57 0 01-.769-1.652 6.118 6.118 0 01-.058-.969 4.324 4.324 0 01.115-1.139 3.175 3.175 0 01.911-1.766 2.09 2.09 0 011.538-.627.978.978 0 01.4.057 1.12 1.12 0 01.713.455 1.729 1.729 0 01.37.855l.228.855a.976.976 0 00.056.4 13.737 13.737 0 01.172 2.165 9.365 9.365 0 01-.057 1.2l-.115.57h.57a5.986 5.986 0 001.653-.285 3.18 3.18 0 001.453-1.054 7.977 7.977 0 001-1.852 1.443 1.443 0 01.256-.37 1.05 1.05 0 00.2-.256.7.7 0 01.513-.171.813.813 0 01.342.057.544.544 0 01.228.456 1.124 1.124 0 01-.114.512 6.105 6.105 0 01-2.564 3.589 5.6 5.6 0 01-2.906.742 6.747 6.747 0 01-1.025-.115 1.022 1.022 0 01-.114.285 7.822 7.822 0 01-1.709 2.479 7 7 0 01-2.45 1.566 5.555 5.555 0 01-1.88.4h-.057a2.311 2.311 0 01-.627.056 4.779 4.779 0 01-3.191-.968 1.385 1.385 0 01-.512-.627 7.687 7.687 0 01-.912-4.045 13.3 13.3 0 01.969-4.986 7.075 7.075 0 012.677-3.5q1.083-.686 1.709-1a3.028 3.028 0 011.368-.313h.057a1.28 1.28 0 011.137.423zm2.507 7.036q0-.513-.114-1.994l-.057-.741a.313.313 0 00-.142-.228.5.5 0 00-.313-.113q-.684 0-.855.854a9.385 9.385 0 00-.114 1.082 2.8 2.8 0 00.342 1.367 2.522 2.522 0 001.082 1.027l.115.056a11.6 11.6 0 00.056-1.31z"
            />
            <path
              data-name="Path 17810"
              d="M93.433 172.559l-.114.456q-.057.172-1 2.592a34.055 34.055 0 01-2.735 5.357 13.768 13.768 0 01-3.5 4.13 3.544 3.544 0 01-1.937.627 4.211 4.211 0 01-1.083-.171q-2.563-1.484-2.564-6.267a32.862 32.862 0 011.083-7.578 6.41 6.41 0 00-.855.684 5.328 5.328 0 01-1 .741 1.973 1.973 0 01-.94.227 3.069 3.069 0 01-.627-.114q-1.595-.513-1.823-1.538a4.895 4.895 0 01-.114-.912 2.324 2.324 0 01.4-1.367 1.171 1.171 0 01.627-.455 2.556 2.556 0 01.911-.172h.171a1.151 1.151 0 01.713.427 1.254 1.254 0 01.313.827 4.917 4.917 0 01-.512 1.937h.056q.342 0 1.254-.912a8.173 8.173 0 011.937-1.538 3.935 3.935 0 011.31-.342h.114a3.113 3.113 0 011.8.569 1.848 1.848 0 01.883 1.425l-.228 1.88q-.4 2.622-.455 3.361-.059.571-.058 1.995 0 2.963.456 3.589v.115a3.644 3.644 0 001.111-.8 6.754 6.754 0 00.883-.969 20.7 20.7 0 002.764-4.758q1.281-2.932 1.4-3.162a.817.817 0 01.8-.455.5.5 0 01.559.571zm-15.5-2.108c0-.228-.1-.342-.285-.342s-.285.114-.285.342a2.161 2.161 0 00.285.855c.037-.076.085-.161.142-.256a1.4 1.4 0 00.143-.37z"
            />
            <path
              data-name="Path 17811"
              d="M99.272 167.489a1.968 1.968 0 01.769 1.6 4.309 4.309 0 01-1.709 2.848l-2.9 2.736.171 1.481a10.011 10.011 0 00.427 1.738 3.271 3.271 0 00.769 1.224 2.464 2.464 0 001.823.627 4.407 4.407 0 001.766-.4 8.536 8.536 0 002.564-2.706 13.984 13.984 0 002.108-4.018c.114-.417.322-.626.627-.626a.494.494 0 01.4.228.92.92 0 01.171.569 1.506 1.506 0 01-.057.456 15.514 15.514 0 01-2.222 4.245 11.643 11.643 0 01-3.761 3.39 9.006 9.006 0 01-3.133 1.025 4.57 4.57 0 01-1.311.171 3.865 3.865 0 01-3.931-2.791 10.376 10.376 0 01-.8-3.988 10.016 10.016 0 01.684-3.647 9.215 9.215 0 011.938-3.077 5.988 5.988 0 011.737-1.14 5.733 5.733 0 011.966-.569h.113a2.754 2.754 0 011.791.624zm-3.048 4.614a7.333 7.333 0 002.222-2.62 1.726 1.726 0 00.057-.512c0-.3-.1-.495-.285-.571a.469.469 0 00-.342-.114 1.694 1.694 0 00-1.253.8 5.625 5.625 0 00-1.311 3.3v.512z"
            />
            <path
              data-name="Path 17812"
              d="M108.419 166.52a1.468 1.468 0 01.969.286 1.762 1.762 0 01.569 1.025 1.472 1.472 0 01.058.455 4.265 4.265 0 01-1.539 3.3 8.421 8.421 0 01-.854.57l.4.455a6.42 6.42 0 001.2.8q.739.4 1.2.684a4.154 4.154 0 00.626.427 2.4 2.4 0 01.8.77 7.3 7.3 0 002.62-1.14 13.051 13.051 0 002.151-1.817 1.276 1.276 0 01.855-.456c.19 0 .3.056.342.171a.833.833 0 01.171.512 2.023 2.023 0 01-.228.571 12.591 12.591 0 01-2.308 1.994 11 11 0 01-2.706 1.367 3.783 3.783 0 01.8 2.278 4.051 4.051 0 01-1.653 3.192 5.2 5.2 0 01-1.851.8 9.221 9.221 0 01-2.364.341 3.574 3.574 0 01-.855-.113v-.057a1.114 1.114 0 01-.8-.513 1.376 1.376 0 01-.285-.911 3.127 3.127 0 01.114-.684 6.063 6.063 0 01.912-1.367 13.714 13.714 0 011.709-1.652l-.57-.627a10.068 10.068 0 00-.854-.656 9.613 9.613 0 01-.969-.769 5.234 5.234 0 01-1.253-1.795 5.446 5.446 0 01-.456-2.193 3.039 3.039 0 01.057-.684 4.579 4.579 0 01.171-1.311q.968-3.248 3.76-3.247zm-.855 4.9a3.922 3.922 0 001.482-2.564 2.218 2.218 0 01.029-.313.524.524 0 00-.086-.37.731.731 0 00-.2-.229.494.494 0 00-.313-.113 1.566 1.566 0 00-.741.4l-.284.342a2.659 2.659 0 00-.513 2.109 3.187 3.187 0 00.342.968zm1.283 10.028a5.016 5.016 0 00.2-2.223 15.851 15.851 0 00-1.538 1.368q-.572.456-.513.855 0 .513.8.74h.171q.563 0 .877-.74z"
            />
            <path
              data-name="Path 17813"
              d="M135.419 149.142a1.628 1.628 0 01.883.542 2.124 2.124 0 01.484.94 3.96 3.96 0 01.171 1.14l-.057.968-.513 16.067-.057 1.139a25.675 25.675 0 00.855 6.154 3.829 3.829 0 001.082 2.336 1.822 1.822 0 001.254.456 2.565 2.565 0 001.6-.57q1.423-1.081 1.824-5.869a.563.563 0 01.626-.569.776.776 0 01.57.227.622.622 0 01.171.571 21.375 21.375 0 01-.883 4.586 6.3 6.3 0 01-2.593 3.789 5.648 5.648 0 01-3.019.968 3.771 3.771 0 01-1.71-.4 4.273 4.273 0 01-2.108-2.394l-.057-.056a1.52 1.52 0 01-.284.4 1.2 1.2 0 00-.228.285 7 7 0 01-3.7 2.451 4.742 4.742 0 01-.855.056 4.331 4.331 0 01-1.794-.4 3.5 3.5 0 01-1.4-1.083 7.5 7.5 0 01-1.426-4.841 11.585 11.585 0 01.8-4.187 11.442 11.442 0 012.336-3.733 4.492 4.492 0 012.9-1.538h.171a1.917 1.917 0 011.367.741l-.057-.342q-.228-2.507-.284-3.76-.059-.684-.058-1.994a30.343 30.343 0 011.425-9.629 5.085 5.085 0 01.855-1.767 2.018 2.018 0 011.424-.684zm-3.7 30.71a7.586 7.586 0 001.652-2.735 7.325 7.325 0 01-.2-1.11q-.086-.712-.142-1.112l-.342-1.88q-.628-3.932-.684-4.159v-.057a1.763 1.763 0 00-1.025-.57.628.628 0 00-.4.228q-.228.228-.513.57a6.545 6.545 0 00-1.082 2.9 17.579 17.579 0 00-.171 2.394q0 1.026.228 3.305a3 3 0 00.854 1.88q.684.684 1.026.683a1.646 1.646 0 00.799-.337z"
            />
            <path
              data-name="Path 17814"
              d="M150.548 167.489a1.971 1.971 0 01.769 1.6 4.313 4.313 0 01-1.709 2.848l-2.906 2.736.171 1.481a10.1 10.1 0 00.427 1.738 3.271 3.271 0 00.769 1.224 2.466 2.466 0 001.823.627 4.411 4.411 0 001.767-.4 8.547 8.547 0 002.56-2.708 13.953 13.953 0 002.107-4.018c.115-.417.323-.626.627-.626a.493.493 0 01.4.228.92.92 0 01.171.569 1.474 1.474 0 01-.057.456 15.458 15.458 0 01-2.222 4.245 11.64 11.64 0 01-3.76 3.39 9.013 9.013 0 01-3.134 1.025 4.568 4.568 0 01-1.31.171 3.865 3.865 0 01-3.931-2.791 10.357 10.357 0 01-.8-3.988 10.036 10.036 0 01.683-3.647 9.215 9.215 0 011.938-3.077 5.988 5.988 0 011.737-1.14 5.733 5.733 0 011.966-.569h.114a2.756 2.756 0 011.8.626zm-3.049 4.614a7.351 7.351 0 002.223-2.62 1.76 1.76 0 00.057-.512c0-.3-.1-.495-.285-.571a.472.472 0 00-.342-.114 1.7 1.7 0 00-1.254.8 5.618 5.618 0 00-1.31 3.3v.512z"
            />
            <path
              data-name="Path 17815"
              d="M176.671 150.511a7.585 7.585 0 013.448 1.624 9.626 9.626 0 012.306 3.276 15.742 15.742 0 011.2 3.9 27.052 27.052 0 01.342 4.473v.4a5.727 5.727 0 01-.115 1.311 11.566 11.566 0 01-1.908 4.443 15.219 15.219 0 01-3.447 3.675 6.141 6.141 0 01-3.59 1.453 9.849 9.849 0 01-4.729-.968l-.342 11.851a3.113 3.113 0 01-.511 2.051 1.684 1.684 0 01-1.427.683 2.277 2.277 0 01-1.879-.854 6.311 6.311 0 01-1.311-3.59q-.512-6.607-.512-12.933 0-7.863.8-16.294a4.015 4.015 0 01.2-1.027 2.063 2.063 0 01.712-1.082c.076-.076.257-.227.542-.456a1.472 1.472 0 01.94-.342 2.8 2.8 0 011.709 1.026l.342.4a6.623 6.623 0 012.508-2.364 6.758 6.758 0 013.246-.826 7.035 7.035 0 011.476.17zm-1.252 21.878a4.4 4.4 0 002.051-2.393 19.906 19.906 0 001.026-2.565c.228-.758.378-1.329.457-1.708a19.27 19.27 0 00.511-4.445 21.6 21.6 0 00-.285-3.305 11.344 11.344 0 00-1.367-3.788 3.9 3.9 0 00-2.565-1.965l-.568-.057a4.966 4.966 0 00-2.906 1.139 13.7 13.7 0 00-1.6 1.652 13.141 13.141 0 01.4 4.444l-.342 11.794a5 5 0 003.42 1.538 4.2 4.2 0 001.768-.341z"
            />
            <path
              data-name="Path 17816"
              d="M192.167 168.286v.114a.44.44 0 01-.142.314.4.4 0 01-.257.142l-.113-.057a1.767 1.767 0 00-1.027-.57.628.628 0 00-.4.228 8.35 8.35 0 00-.513.57 6.569 6.569 0 00-1.083 2.9 17.9 17.9 0 00-.171 2.394q0 1.026.229 3.305a2.994 2.994 0 00.854 1.88q.684.684 1.026.683a1.64 1.64 0 00.8-.342 6.393 6.393 0 001.6-2.621c-.076-.456-.151-1.176-.227-2.165l-.171-2.108a15.189 15.189 0 01-.057-1.538q0-.8.113-2.507l.058-1.253a1.119 1.119 0 01.342-.912.612.612 0 01.456-.171q.056 0 .74.171a3.324 3.324 0 011.994 1.425 3.571 3.571 0 01.285 1.652v2.62a19.625 19.625 0 00.285 3.647 3.146 3.146 0 001.083 2.336 2.871 2.871 0 001.481.627 2.2 2.2 0 001.367-.627 4.238 4.238 0 001.225-2.165 15.682 15.682 0 00.4-2.393q.085-1.081.143-1.481a.817.817 0 01.256-.513.718.718 0 01.484-.171q.627 0 .627.854a17.083 17.083 0 01-.826 4.672 6.094 6.094 0 01-2.65 3.818 5.648 5.648 0 01-3.019.968 3.423 3.423 0 01-1.652-.4 4.507 4.507 0 01-2.165-2.394l-.457.627a6.993 6.993 0 01-3.7 2.451 4.737 4.737 0 01-.854.056 4.332 4.332 0 01-1.795-.4 3.5 3.5 0 01-1.4-1.083 7.5 7.5 0 01-1.43-4.834 11.605 11.605 0 01.8-4.187 11.476 11.476 0 012.336-3.733 4.5 4.5 0 012.906-1.538h.171q1.136 0 2.048 1.709z"
            />
            <path
              data-name="Path 17817"
              d="M208.519 155.353a4.586 4.586 0 011.6 1.168 5.208 5.208 0 011.025 1.738 7.392 7.392 0 01.4 2.45 12.737 12.737 0 01-1.252 5.5 13.675 13.675 0 01-3.419 4.529q-.115 1.368-.115 4.1a56.78 56.78 0 00.285 6.153 8.023 8.023 0 00.571 2.278 2.384 2.384 0 001.765 1.425h.115a2.955 2.955 0 001.509-.655 5.28 5.28 0 001.454-1.453 8.856 8.856 0 001.338-3.219 39.158 39.158 0 00.6-4.587c.076-.987.151-1.709.229-2.165a.8.8 0 01.341-.541 1.069 1.069 0 01.627-.2.758.758 0 01.6.227.852.852 0 01.143.684l-.171 2.165a31.382 31.382 0 01-.912 5.67 11.079 11.079 0 01-2.678 4.472 11.965 11.965 0 01-2.393 1.852 5.048 5.048 0 01-2.563.769 3.926 3.926 0 01-2.308-.741 6.115 6.115 0 01-1.738-1.88 14.5 14.5 0 01-1.311-4.786 53.989 53.989 0 01-.4-6.78 56.56 56.56 0 012.222-15.782 4.815 4.815 0 01.627-1.6v-.056l.17-.171a.051.051 0 01.057-.057.1.1 0 01.114-.114 2.549 2.549 0 011.88-.74 3.758 3.758 0 011.588.347zm1.111 9.8a9.637 9.637 0 00.94-4.159 4.257 4.257 0 00-.968-3.021 2.534 2.534 0 00-.57-.455 9.343 9.343 0 01-.4 2.45 56.878 56.878 0 00-1.6 8.546 9.631 9.631 0 002.597-3.361z"
            />
            <path
              data-name="Path 17818"
              d="M222.219 167.489a1.966 1.966 0 01.77 1.6 4.313 4.313 0 01-1.709 2.848l-2.907 2.736.171 1.481a10.189 10.189 0 00.428 1.738 3.273 3.273 0 00.77 1.224 2.463 2.463 0 001.823.627 4.4 4.4 0 001.765-.4 8.547 8.547 0 002.565-2.706 14 14 0 002.107-4.018c.115-.417.323-.626.627-.626a.493.493 0 01.4.228.92.92 0 01.171.569 1.506 1.506 0 01-.057.456 15.486 15.486 0 01-2.222 4.245 11.64 11.64 0 01-3.76 3.39 9.02 9.02 0 01-3.134 1.025 4.575 4.575 0 01-1.311.171 3.865 3.865 0 01-3.93-2.791 10.357 10.357 0 01-.8-3.988 10.015 10.015 0 01.683-3.647 9.215 9.215 0 011.938-3.077 6 6 0 011.737-1.14 5.739 5.739 0 011.966-.569h.114a2.754 2.754 0 011.795.624zm-3.048 4.614a7.34 7.34 0 002.223-2.62 1.76 1.76 0 00.057-.512c0-.3-.1-.495-.286-.571a.468.468 0 00-.341-.114 1.694 1.694 0 00-1.253.8 5.606 5.606 0 00-1.311 3.3v.512z"
            />
            <path
              data-name="Path 17819"
              d="M238.287 168.002a3.007 3.007 0 011.283 1.139 2.744 2.744 0 01.484 1.2q.086.627.142 1.823c0 1.253.019 2.185.057 2.791l-.057.514-.057 1.765a11 11 0 00-.057 1.2 5 5 0 01-.086.741 3.388 3.388 0 00-.028.969 1.476 1.476 0 00.057.455l.114.341a1.374 1.374 0 00.969.969h.056q2.337 0 3.391-4.757t1.11-5.043a.589.589 0 01.571-.4.637.637 0 01.512.2.544.544 0 01.114.485 3.76 3.76 0 00-.114.484c-.038.209-.095.466-.171.769q-1.14 6.21-3.589 8.831a5.449 5.449 0 01-2.678 1.709l-.513.057-.342.058h-.056a2.6 2.6 0 01-.912-.171h-.115a3.509 3.509 0 01-2.05-1.823 10.754 10.754 0 01-.8-4.672 20.391 20.391 0 01.228-2.85v-.113q.055-.285.171-1.14a12.275 12.275 0 00.113-1.6q0-1.935-1.025-1.936a1.257 1.257 0 00-.4.057 3.791 3.791 0 00-1.6 1.2 12.758 12.758 0 00-1.481 2.052q-.059 3.02-.513 7.349a.929.929 0 01-.228.684l-.171.114-.285.057-.4-.057a3.6 3.6 0 01-1.453-.826 3.433 3.433 0 01-.941-1.4 5.045 5.045 0 01-.218-1.592l.056-1.14.229-2.45.171-2.393v-.4a6.266 6.266 0 00-.058-.826l-.113-.826a2.686 2.686 0 00-.058-.626 1.482 1.482 0 01.058-.457.61.61 0 01.455-.17.336.336 0 01.228.056 6.2 6.2 0 011.653.4 2.871 2.871 0 011.253 1.026 1.64 1.64 0 01.285.855l.114-.058a9.06 9.06 0 011.823-1.681 8.965 8.965 0 011.937-1.053l.114-.057.114-.058a2.839 2.839 0 01.855-.171h.113a3.945 3.945 0 011.739.396z"
            />
            <path
              data-name="Path 17820"
              d="M257.06 171.477a1.128 1.128 0 01.342.912 4.71 4.71 0 01-.115.854 37.02 37.02 0 01-5.126 12.021 4.373 4.373 0 011.367-.625 4.777 4.777 0 011.2-.229l.569.058a58.79 58.79 0 007.008-11.567 1.112 1.112 0 011.14-.683 1.3 1.3 0 01.4.056.628.628 0 01.4.627 1.631 1.631 0 01-.171.741 30.364 30.364 0 01-2.621 4.815q-1.539 2.307-3.989 5.611l-.8 1.083a2.4 2.4 0 01.855 1.339 13.06 13.06 0 01.228 2.137 38.108 38.108 0 01-.684 8.318 12.833 12.833 0 01-.684 2.25 3.268 3.268 0 01-1.139 1.453 3.2 3.2 0 01-1.767.513 3.562 3.562 0 01-2.336-.8 4.762 4.762 0 01-1.395-1.908 6.249 6.249 0 01-.485-2.422 9.784 9.784 0 011.14-4.387 28.564 28.564 0 012.792-4.273.732.732 0 00.284-.456q-.114-.741-.684-.8a.226.226 0 00-.17-.058 2.2 2.2 0 00-1.026.456 6 6 0 01-1.139.627 2.556 2.556 0 01-1.082.171h-.171a3.7 3.7 0 01-2.678-.8 2.063 2.063 0 01-.456-1.424 4.811 4.811 0 01.114-.912 8.565 8.565 0 011.138-2.158 45.416 45.416 0 004.729-9.059l-.114.114-5.185.285h-.341a1.567 1.567 0 01-.684-.114.728.728 0 01-.513-.854q.114-.513 1.2-.627 3.075-.171 9.229-.626h.4a1.391 1.391 0 011.02.341zm-4.387 28.088a1.491 1.491 0 00.684-1.48l.171-9.06a17.29 17.29 0 00-2.336 3.761 9.642 9.642 0 00-.684 3.19 6.3 6.3 0 00.513 2.507 3.7 3.7 0 00.683.969 2.033 2.033 0 00.57.228.922.922 0 00.399-.115z"
            />
            <path
              data-name="Path 17821"
              d="M265.519 167.375a1.456 1.456 0 01.768 1.253 31.174 31.174 0 01-.227 3.875q-.287 4.272-.285 4.957a4.147 4.147 0 00.4 2.392 1.3 1.3 0 00.74.286 2.459 2.459 0 001.368-.513 5.872 5.872 0 001.368-1.368 14.1 14.1 0 01-.285-2.165q-.059-1.31 0-3.533t.114-2.9q0-1.539.4-1.823a3.483 3.483 0 011.823-.513h.4a2.668 2.668 0 011.881.911 2.339 2.339 0 01.4 1.368 6.988 6.988 0 01-.114 1.083 30.1 30.1 0 00-.455 4.9 12.3 12.3 0 00.4 3.476q.4 1.311 1.2 1.31a.777.777 0 00.341-.057 5.955 5.955 0 002.678-3.077 18.821 18.821 0 001.71-4.957.679.679 0 01.228-.37.608.608 0 01.4-.142.8.8 0 01.342.057.528.528 0 01.2.314 1.53 1.53 0 01.085.483 14.894 14.894 0 01-1.054 3.505 14.339 14.339 0 01-2.165 3.589 5.588 5.588 0 01-3.105 2.022 4.732 4.732 0 01-.855.115 4.574 4.574 0 01-2.392-.684 4.785 4.785 0 01-1.767-1.823 9.288 9.288 0 01-2.706 2.136 5.869 5.869 0 01-2.535.827h-.058a3.637 3.637 0 01-1.624-.513 3.077 3.077 0 01-1.168-1.2 4.171 4.171 0 01-.4-1.88l.057-1.026a4.7 4.7 0 00.029-.882 9.837 9.837 0 01-.029-.713 8.483 8.483 0 01-.057-1.6v-1.823a18.381 18.381 0 01.227-2.907c.039-.151.105-.512.2-1.082a2.56 2.56 0 01.512-1.282 1.249 1.249 0 011-.427h.228a3.643 3.643 0 011.782.401z"
            />
            <path
              data-name="Path 17822"
              d="M287.341 167.489a1.966 1.966 0 01.77 1.6 4.313 4.313 0 01-1.709 2.848l-2.907 2.736.171 1.481a10.192 10.192 0 00.428 1.738 3.274 3.274 0 00.77 1.224 2.463 2.463 0 001.823.627 4.4 4.4 0 001.765-.4 8.547 8.547 0 002.567-2.708 13.977 13.977 0 002.107-4.018c.115-.417.323-.626.627-.626a.493.493 0 01.4.228.92.92 0 01.171.569 1.505 1.505 0 01-.057.456 15.486 15.486 0 01-2.222 4.245 11.64 11.64 0 01-3.76 3.39 9.02 9.02 0 01-3.134 1.025 4.575 4.575 0 01-1.311.171 3.865 3.865 0 01-3.93-2.791 10.357 10.357 0 01-.8-3.988 10.015 10.015 0 01.683-3.647 9.215 9.215 0 011.938-3.077 6 6 0 011.737-1.14 5.739 5.739 0 011.966-.569h.114a2.754 2.754 0 011.793.626zm-3.048 4.614a7.34 7.34 0 002.223-2.62 1.76 1.76 0 00.057-.512c0-.3-.1-.495-.286-.571a.468.468 0 00-.341-.114 1.694 1.694 0 00-1.253.8 5.606 5.606 0 00-1.311 3.3v.512z"
            />
            <path
              data-name="Path 17823"
              d="M299.107 155.353a4.582 4.582 0 011.6 1.168 5.21 5.21 0 011.026 1.738 7.391 7.391 0 01.4 2.45 12.724 12.724 0 01-1.253 5.5 13.661 13.661 0 01-3.419 4.529q-.114 1.368-.114 4.1a56.78 56.78 0 00.285 6.153 8.022 8.022 0 00.571 2.278 2.382 2.382 0 001.765 1.425h.115a2.958 2.958 0 001.509-.655 5.262 5.262 0 001.453-1.453 8.841 8.841 0 001.339-3.219 39.383 39.383 0 00.6-4.587q.114-1.48.228-2.165a.8.8 0 01.342-.541 1.069 1.069 0 01.627-.2.758.758 0 01.6.227.848.848 0 01.142.684l-.17 2.165a31.374 31.374 0 01-.913 5.67 11.076 11.076 0 01-2.677 4.472 11.966 11.966 0 01-2.393 1.852 5.048 5.048 0 01-2.563.769 3.926 3.926 0 01-2.308-.741 6.115 6.115 0 01-1.738-1.88 14.478 14.478 0 01-1.311-4.786 53.992 53.992 0 01-.4-6.78 56.6 56.6 0 012.221-15.782 4.858 4.858 0 01.627-1.6v-.056l.171-.171a.051.051 0 01.057-.057.1.1 0 01.114-.114 2.548 2.548 0 011.88-.74 3.761 3.761 0 011.587.347zm1.111 9.8a9.637 9.637 0 00.94-4.159 4.257 4.257 0 00-.968-3.021 2.513 2.513 0 00-.571-.455 9.337 9.337 0 01-.4 2.45 56.75 56.75 0 00-1.6 8.546 9.631 9.631 0 002.6-3.361z"
            />
            <path
              data-name="Path 17824"
              d="M312.838 168.286v.114a.444.444 0 01-.142.314.4.4 0 01-.257.142l-.113-.057a1.767 1.767 0 00-1.027-.57.628.628 0 00-.4.228q-.23.228-.513.57a6.557 6.557 0 00-1.083 2.9 17.9 17.9 0 00-.171 2.394q0 1.026.229 3.305a2.994 2.994 0 00.854 1.88q.684.684 1.026.683a1.64 1.64 0 00.8-.342 6.4 6.4 0 001.6-2.621c-.076-.456-.151-1.176-.227-2.165l-.171-2.108a15.188 15.188 0 01-.057-1.538q0-.8.113-2.507l.058-1.253a1.119 1.119 0 01.342-.912.612.612 0 01.456-.171q.056 0 .74.171a3.324 3.324 0 011.994 1.425 3.571 3.571 0 01.285 1.652v2.62a19.721 19.721 0 00.284 3.647 3.15 3.15 0 001.084 2.336 2.871 2.871 0 001.481.627 2.2 2.2 0 001.367-.627 4.238 4.238 0 001.225-2.165 15.684 15.684 0 00.4-2.393q.086-1.081.143-1.481a.817.817 0 01.256-.513.716.716 0 01.484-.171q.627 0 .627.854a17.083 17.083 0 01-.826 4.672 6.094 6.094 0 01-2.65 3.818 5.648 5.648 0 01-3.019.968 3.427 3.427 0 01-1.653-.4 4.515 4.515 0 01-2.165-2.394l-.456.627a6.993 6.993 0 01-3.7 2.451 4.736 4.736 0 01-.854.056 4.332 4.332 0 01-1.795-.4 3.5 3.5 0 01-1.4-1.083 7.5 7.5 0 01-1.43-4.834 11.605 11.605 0 01.8-4.187 11.476 11.476 0 012.336-3.733 4.5 4.5 0 012.906-1.538h.17q1.137 0 2.049 1.709z"
            />
          </g>
        </g>
        <g data-name={3}>
          <g data-name="Group 820">
            <path
              data-name="Path 17825"
              d="M160.864 118.285v105.339h17.5c.3-11.958.645-33.266.857-48.441-6.338-2.109-8.422-11.241-7.906-21 .648-12.276 5.315-28.318 8.945-33.978 2.457-3.834 5.38-1.713 5.38.724 0 1.846-.559 47.228-.449 54.2.244 15.182.571 36.523.862 48.5h14.213V94.965z"
              fill="url(#Palenzuela_svg__a)"
              transform="translate(-26.681 -94.965)"
            />
            <path
              data-name="Path 17826"
              d="M207.25 118.285v105.339h15.85c.387-15.512.857-46.764 1.015-59.931-20.937-8.588-9.057-45.595 2.831-45.595s23.767 37.007 2.836 45.595c.151 13.167.622 44.419 1.008 59.931h15.857V94.965z"
              fill="url(#Palenzuela_svg__a)"
              transform="translate(-26.681 -94.965)"
            />
            <path
              data-name="Path 17827"
              d="M114.479 118.285v105.339h15.856c.391-15.723.87-47.6 1.017-60.452a11.581 11.581 0 01-5.4-5.638c-1.975-4.281-1.791-8.8-1.286-13.39.41-3.776 2.593-25.737 2.593-25.737 0-.411.778-.411.778 0l.17 25.762a.909.909 0 101.8 0l1.642-25.762c0-.411.777-.411.777 0l.816 25.762a.942.942 0 101.875 0l.817-25.762c0-.411.775-.411.775 0l1.643 25.762a.909.909 0 101.8 0l.171-25.762c0-.411.775-.411.775 0 0 0 2.187 21.961 2.6 25.737.5 4.593.692 9.109-1.286 13.39a11.563 11.563 0 01-5.412 5.638c.151 12.85.628 44.729 1.023 60.452h15.857V94.965z"
              fill="url(#Palenzuela_svg__a)"
              transform="translate(-26.681 -94.965)"
            />
            <path
              data-name="Path 17828"
              d="M191.72 221.157c-65.606 0-124.537-12.656-165.039-32.756 35.332 26.226 95.446 43.491 163.668 43.491 64.8 0 122.294-15.578 158.175-39.619-40.055 17.84-95.517 28.884-156.804 28.884z"
              fill="url(#Palenzuela_svg__b)"
              transform="translate(-26.681 -94.965)"
            />
          </g>
          <g data-name="Group 822">
            <g data-name="Group 821" fill="#e9d8ab">
              <path
                data-name="Path 17829"
                d="M68.347 208.873h6.708v1h-6.708z"
              />
              <path
                data-name="Path 17830"
                d="M90.481 214.086a4.843 4.843 0 01-3.45 1.418 4.83 4.83 0 110-9.659 4.924 4.924 0 013.45 1.418l-.613.594a4.019 4.019 0 00-2.837-1.131 3.95 3.95 0 100 7.9 4.009 4.009 0 002.818-1.169z"
              />
              <path
                data-name="Path 17831"
                d="M92.147 210.693a4.812 4.812 0 119.621 0 4.811 4.811 0 11-9.621 0zm8.7 0a3.893 3.893 0 10-7.781 0 3.891 3.891 0 107.781 0z"
              />
              <path
                data-name="Path 17832"
                d="M117.961 215.408v-5.673a2.928 2.928 0 00-3.028-3.1 2.991 2.991 0 00-3.047 3.162v5.615h-.94v-5.615a3.039 3.039 0 10-6.075 0v5.615h-.939v-9.477h.882l.019 1.572a3.335 3.335 0 013.086-1.763 3.481 3.481 0 013.507 2.338 3.658 3.658 0 013.507-2.338 3.808 3.808 0 013.967 3.986v5.673z"
              />
              <path
                data-name="Path 17833"
                d="M122.788 202.855a.757.757 0 01-1.514 0 .757.757 0 011.514 0zm-1.245 3.047v9.506h.939v-9.506z"
              />
              <path
                data-name="Path 17834"
                d="M134.248 201.992v13.416h-.92v-2.089a4.455 4.455 0 01-3.947 2.242 4.889 4.889 0 01.019-9.774 4.19 4.19 0 013.928 2.224v-6.019zm-8.681 8.683a3.674 3.674 0 003.871 3.966 3.978 3.978 0 000-7.953 3.671 3.671 0 00-3.871 3.987z"
              />
              <path
                data-name="Path 17835"
                d="M146.053 205.935v9.468h-.94v-2.008a4.424 4.424 0 01-3.871 2.166 4.868 4.868 0 010-9.735 4.293 4.293 0 013.871 2.185v-2.076zm-8.682 4.735a3.8 3.8 0 003.871 3.966c5.271 0 5.271-7.915 0-7.915a3.787 3.787 0 00-3.871 3.954z"
              />
              <path
                data-name="Path 17836"
                d="M153.333 210.675a4.76 4.76 0 014.811-4.888c2.663 0 5.04 1.629 4.675 5.328h-8.528a3.725 3.725 0 003.853 3.507 4.453 4.453 0 003.411-1.437l.671.537a5.206 5.206 0 01-4.082 1.82 4.643 4.643 0 01-4.811-4.867zm8.644-.384c-.02-2.338-1.553-3.642-3.833-3.642a3.786 3.786 0 00-3.853 3.642z"
              />
              <path
                data-name="Path 17837"
                d="M171.749 207.666a4.044 4.044 0 00-2.951-1c-1.476-.02-2.894.536-2.856 1.744.038 1.265 1.687 1.514 2.875 1.725 1.687.288 4.005.575 3.909 2.779-.057 2.089-2.222 2.644-3.89 2.644a4.88 4.88 0 01-4.14-1.9l.69-.613a4.206 4.206 0 003.469 1.61c1.151 0 2.894-.307 2.951-1.8.039-1.361-1.533-1.629-3.085-1.878-1.839-.306-3.66-.652-3.679-2.55-.019-1.858 1.84-2.663 3.756-2.644a4.961 4.961 0 013.526 1.266z"
              />
              <path
                data-name="Path 17838"
                d="M174.814 219.356v-13.421h.92v2.242a4.515 4.515 0 014.1-2.376 4.892 4.892 0 01-.211 9.775 4.272 4.272 0 01-3.91-2.358v6.133zm8.682-8.681a3.647 3.647 0 00-3.871-3.968 3.99 3.99 0 000 7.973 3.677 3.677 0 003.871-4.005z"
              />
              <path
                data-name="Path 17839"
                d="M195.837 205.935v9.468h-.939v-2.008a4.427 4.427 0 01-3.872 2.166 4.868 4.868 0 010-9.735 4.3 4.3 0 013.872 2.185v-2.076zm-8.682 4.735a3.8 3.8 0 003.871 3.966c5.271 0 5.271-7.915 0-7.915a3.787 3.787 0 00-3.871 3.954z"
              />
              <path
                data-name="Path 17840"
                d="M199.303 205.935v1.687a3.768 3.768 0 013.373-1.859 3.95 3.95 0 014.275 4.178v5.462h-.94v-5.438a3.031 3.031 0 00-3.373-3.277 3.289 3.289 0 00-3.319 3.447v5.27h-.94v-9.47zm.978-2.7a1.426 1.426 0 012.128-1.035l.785.4c.46.193 1.016.135 1.169-.459l.652.192a1.426 1.426 0 01-2.146 1.035l-.786-.4a.779.779 0 00-1.151.46z"
              />
              <path
                data-name="Path 17841"
                d="M208.962 210.693a4.812 4.812 0 119.621 0 4.811 4.811 0 11-9.621 0zm8.7 0a3.893 3.893 0 10-7.781 0 3.891 3.891 0 107.781 0z"
              />
              <path
                data-name="Path 17842"
                d="M221.686 201.992v13.416h-.939v-13.416z"
              />
              <path
                data-name="Path 17843"
                d="M233.419 205.935v9.468h-.939v-2.008a4.427 4.427 0 01-3.872 2.166 4.868 4.868 0 010-9.735 4.3 4.3 0 013.872 2.185v-2.076zm-8.682 4.735a3.8 3.8 0 003.871 3.966c5.271 0 5.271-7.915 0-7.915a3.787 3.787 0 00-3.875 3.954z"
              />
              <path
                data-name="Path 17844"
                d="M241.289 208.873h6.708v1h-6.708z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgPalenzuela;
