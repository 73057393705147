import * as React from "react";

function SvgGetInTouch(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 282 58"
      {...props}
    >
      <defs>
        <filter
          id="get-in-touch_svg__a"
          x={0}
          y={0}
          width={282}
          height={58}
          filterUnits="userSpaceOnUse"
        >
          <feOffset />
          <feGaussianBlur stdDeviation={3.5} result="blur" />
          <feFlood />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Desktop_Get in touch">
        <g data-name="Group 58">
          <g filter="url(#get-in-touch_svg__a)">
            <g
              data-name="Rectangle 19"
              transform="translate(10.5 10.5)"
              fill="#1e1e20"
              stroke="#fff"
            >
              <rect width={261} height={37} rx={18.5} stroke="none" />
              <rect
                x={0.5}
                y={0.5}
                width={260}
                height={36}
                rx={18}
                fill="none"
              />
            </g>
          </g>
          <text
            data-name="Get in touch"
            transform="translate(82.5 34.5)"
            fill="#95d8e6"
            fontSize={20}
            letterSpacing=".04em"
          >
            <tspan x={0} y={0}>
              {"Get in touch"}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default SvgGetInTouch;
