import * as React from "react";

function SvgGmail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 70 68"
      {...props}
    >
      <defs>
        <linearGradient
          id="Gmail_svg__a"
          x1={0.287}
          y1={0.887}
          x2={0.503}
          y2={0.558}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#e3e3e3" />
          <stop offset={1} stopColor="#e2e2e2" />
        </linearGradient>
        <linearGradient
          id="Gmail_svg__b"
          x1={0.499}
          y1={0.468}
          x2={0.761}
          y2={0.047}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#f5f5f5" />
          <stop offset={0.03} stopColor="#eee" />
          <stop offset={1} stopColor="#eee" />
        </linearGradient>
        <linearGradient
          id="Gmail_svg__c"
          y1={0.563}
          x2={1}
          y2={0.563}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#d74a39" />
          <stop offset={1} stopColor="#c73d28" />
        </linearGradient>
        <linearGradient
          id="Gmail_svg__d"
          x1={0.5}
          y1={1.063}
          x2={0.5}
          y2={0.119}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopOpacity={0.149} />
          <stop offset={1} stopOpacity={0.031} />
        </linearGradient>
        <linearGradient
          id="Gmail_svg__e"
          y1={1.247}
          x2={1}
          y2={1.247}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#f5f5f5" />
          <stop offset={1} stopColor="#f5f5f5" />
        </linearGradient>
        <linearGradient
          id="Gmail_svg__f"
          x1={0.5}
          y1={1.724}
          x2={0.5}
          y2={0.729}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#e05141" />
          <stop offset={1} stopColor="#de4735" />
        </linearGradient>
      </defs>
      <g transform="translate(-.375 .132)">
        <rect
          data-name="Rectangle 22"
          width={70}
          height={68}
          rx={10}
          transform="translate(.375 -.132)"
          fill="#0b85e0"
        />
        <g data-name="Group 36">
          <path
            data-name="Path 8595"
            d="M48.287 44.7H5.885A2.885 2.885 0 013 41.817V10.885A2.885 2.885 0 015.885 8h42.4a2.885 2.885 0 012.886 2.885v30.931a2.885 2.885 0 01-2.884 2.884z"
            transform="translate(9.045 8.606)"
            fill="url(#Gmail_svg__a)"
          />
          <path
            data-name="Path 8596"
            d="M47.552 44.261H8L50.437 11v30.376a2.885 2.885 0 01-2.885 2.885z"
            transform="translate(9.78 9.047)"
            fill="url(#Gmail_svg__b)"
          />
          <path
            data-name="Path 8597"
            d="M5.885 8h2.85v36.7h-2.85A2.885 2.885 0 013 41.817V10.885A2.885 2.885 0 015.885 8zm42.4 0h-2.85v36.7h2.85a2.885 2.885 0 002.885-2.885v-30.93A2.885 2.885 0 0048.287 8z"
            transform="translate(9.045 8.606)"
            fill="url(#Gmail_svg__c)"
          />
          <path
            data-name="Path 8598"
            d="M48.287 44.7H34.558L3 12v-1.114A2.885 2.885 0 015.885 8h42.4a2.885 2.885 0 012.886 2.885v30.931a2.885 2.885 0 01-2.884 2.884z"
            transform="translate(9.045 8.606)"
            fill="url(#Gmail_svg__d)"
          />
          <path
            data-name="Path 8599"
            d="M49.82 13.85L27.086 30.318 4.351 13.85A3.268 3.268 0 013 11.2v-.319A2.885 2.885 0 015.885 8h42.4a2.885 2.885 0 012.886 2.885v.315a3.268 3.268 0 01-1.351 2.65z"
            transform="translate(9.045 8.606)"
            fill="url(#Gmail_svg__e)"
          />
          <path
            data-name="Path 8600"
            d="M48.287 8h-.344L27.086 23.107 6.229 8h-.344A2.885 2.885 0 003 10.885v.315a3.268 3.268 0 001.351 2.65l22.735 16.468L49.82 13.85a3.268 3.268 0 001.351-2.65v-.319A2.885 2.885 0 0048.287 8z"
            transform="translate(9.045 8.606)"
            fill="url(#Gmail_svg__f)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgGmail;
