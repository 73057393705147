import React from "react";
import { Link } from "react-scroll";

import Home from "../svgComponents/Home";
import About from "../svgComponents/About";
import Work from "../svgComponents/Work";
import Client from "../svgComponents/Client";
import Contact from "../svgComponents/Contact";

export const Menu = () => {
  return (
    <div className="menu">
      <Link
        to="home"
        smooth={true}
        duration={1000}
        activeClass={"active"}
        spy={true}
      >
        <Home fill="#444" className="menu__icon" />
        <span className="menu__text">Home</span>
      </Link>
      <Link
        to="about"
        smooth={true}
        duration={1000}
        activeClass={"active"}
        spy={true}
      >
        <About fill="#444" className="menu__icon" />
        <span className="menu__text">About</span>
      </Link>
      <Link
        to="work"
        smooth={true}
        duration={1000}
        activeClass={"active"}
        spy={true}
      >
        <Work fill="#444" className="menu__icon" />
        <span className="menu__text">Work</span>
      </Link>
      <Link
        to="clients"
        smooth={true}
        duration={1000}
        activeClass={"active"}
        spy={true}
      >
        <Client fill="#444" className="menu__icon" />
        <span className="menu__text">Clients</span>
      </Link>
      <Link
        to="contact"
        smooth={true}
        duration={1000}
        activeClass={"active"}
        spy={true}
      >
        <Contact fill="#444" className="menu__icon" />
        <span className="menu__text">Contact</span>
      </Link>
    </div>
  );
};
