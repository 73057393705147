import * as React from "react";

function SvgAbout(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51.3 51.3"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M45.5.02H5.8C2.6.02 0 2.62 0 5.82v39.7c0 3.2 2.6 5.8 5.8 5.8h39.7c3.2 0 5.8-2.6 5.8-5.8V5.82c0-3.2-2.6-5.8-5.8-5.8zm-13.9 39.8H19.7v-28.4h11.9v28.4z"
        fill="#474748"
      />
    </svg>
  );
}

export default SvgAbout;
