import React  from 'react';

import {Header} from './components/Header'
import {Menu} from './components/Menu'
import {Home} from './components/Home'
import {About} from './components/About'
import {Clients} from './components/Clients'
import {Contact} from './components/Contact'
import {Work} from './components/Work'

import {ContextProvider} from '../src/context/Context'

import { OutsideAlerter } from "./hooks/OutsideAlerter";

function App() {
  
  return (
    <>
    <ContextProvider>
      <div className="container">
        <Header />
        <div className="content">
          <div className="content__menu">
          <Menu/>
          </div>
          <div className="content__content">
          <Home/>
          <About/>
          <OutsideAlerter>
             <Work/>
          </OutsideAlerter>
          <Clients/>
          <Contact/>
          </div>
        </div>
      </div>
    </ContextProvider>
    </>
  );
}


export default App;
