import * as React from "react";

function SvgLinkedin(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 70 68"
      {...props}
    >
      <g transform="translate(-.125 .132)">
        <rect
          data-name="Rectangle 23"
          width={70}
          height={68}
          rx={10}
          transform="translate(.125 -.132)"
          fill="#0b85e0"
        />
        <path
          data-name="Path 8588"
          d="M14.181 24.445h3.136a3.135 3.135 0 013.136 3.136v26.654a3.135 3.135 0 01-3.136 3.136h-3.136a3.135 3.135 0 01-3.136-3.136V27.58a3.135 3.135 0 013.136-3.135zm1.538-4.7h-.052a4.5 4.5 0 01-4.622-4.71 4.526 4.526 0 014.733-4.7 4.507 4.507 0 014.674 4.7 4.529 4.529 0 01-4.733 4.707zm39.226 37.623h-3.138a3.135 3.135 0 01-3.136-3.133v-14.7c0-4.31-2.361-7.249-6.646-7.249a6.016 6.016 0 00-5.685 3.9 14.068 14.068 0 00-.212 3.545v14.504a3.125 3.125 0 01-3.125 3.136h-3.145a3.135 3.135 0 01-3.135-3.136V27.58a3.135 3.135 0 013.136-3.136h3.146a3.135 3.135 0 013.14 3.136v1.6c1.417-2.189 4.009-4.736 9.667-4.736 7.013 0 12.271 4.018 12.271 13.862v15.929a3.135 3.135 0 01-3.138 3.133z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgLinkedin;
