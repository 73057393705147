import React from "react";
import { SubTextComponent } from "./SubTextComponent";
import { Contact as ContactIcon } from "../svgComponents";

import { Facebook, Gmail, Instagram, Linkedin } from "../contactIconsComp";

export const Contact = (props) => {
  return (
    <section className="contact">
      <div className="mobile-selector">
                <span className="mobile-selector__icon">
                    <ContactIcon/>
                </span>
                <h2>Contact</h2>
            </div>
      <SubTextComponent
        offset={props.offSetApp}
        titulo="Get in"
        tituloBreak=" touch."
      />
      <div className="contact__icons">
        <a
          alt="instagram"
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/bytesstudio/"
        >
          <Instagram />
        </a>
        <a
          alt="linkedin"
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/company/bytes-studio/mycompany/"
        >
          <Linkedin />
        </a>
        <a
          alt="facebook"
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/bytesstudio/"
        >
          <Facebook />
        </a>
        <a
          alt="instagram"
          target="_blank"
          rel="noreferrer"
          href="mailto:info@bytesstudio.com"
        >
          <Gmail />
        </a>
      </div>
    </section>
  );
};
