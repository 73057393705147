import React, { useEffect, useState } from "react";
import iphone1 from "../images/iphone1.png";
import iphone2 from "../images/iphone2.png";
import { TextComponent } from "./TextComponent";
import { FlechaLeft } from '../svgComponents'

export const Home = (props) => {
  const [year, setYear] = useState("");

  useEffect(() => {
    setYear(new Date().getFullYear());
  },[]);

  return (
    <>
      <section id="home" className="portada">
        <div className="portada__left">
          <TextComponent
            offset={props.offSetApp}
            titulo="We"
            tituloBreak="imagine"
            subtitulo="the"
            subtituloBreak="imposible"
          />
        </div>
        <div className="portada__right">
         


         
        </div>
      </section>
     
    </>
  );
};
